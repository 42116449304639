var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"660","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-1",attrs:{"color":"success","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link-plus")]),_vm._v(" Add File")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        Add New File\n      ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.dialog)?_c('v-text-field',{attrs:{"autofocus":true,"label":"Dropbox link *","required":"","error-messages":_vm.link_error,"hint":"https://www.dropbox.com/s/n30xwtpbpv7x2i2/Akudama%20Drive%20Season%201%20Episode%2001.mp4?dl=0 <br> https://www.dropbox.com/scl/fi/f34bn7iyg999fvm66fvas/Spider-Man-Across-The-Spider-Verse-2023.mp4?rlkey=mohwaheac6ei5baj1ma0nlrcb&dl=0"},on:{"change":_vm.link_change},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.add_file}},[_vm._v("\n          Add\n        ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false;
            _vm.link = '';
            _vm.link_error = '';}}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"660","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1 mb-1",attrs:{"color":"success","dark":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download-multiple")]),_vm._v(" Add Multi")],1)]}}]),model:{value:(_vm.dialog_multi),callback:function ($$v) {_vm.dialog_multi=$$v},expression:"dialog_multi"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        Import Multiple IDs\n      ")]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"formMulti"},[(_vm.dialog_multi)?_c('v-textarea',{attrs:{"persistent-hint":"","label":"Dropbox Links *","required":"","error-messages":_vm.links_error,"rules":_vm.rules,"hint":"<strong>Separated by newlines</strong> <br><br> https://www.dropbox.com/s/n30xwtpbpv7x2i2/Akudama%20Drive%20Season%201%20Episode%2001.mp4?dl=0 <br> https://www.dropbox.com/scl/fi/f34bn7iyg999fvm66fvas/Spider-Man-Across-The-Spider-Verse-2023.mp4?rlkey=mohwaheac6ei5baj1ma0nlrcb&dl=0"},on:{"change":_vm.links_change},model:{value:(_vm.links),callback:function ($$v) {_vm.links=$$v},expression:"links"}}):_vm._e()],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.import_links}},[_vm._v("\n          Import\n        ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_multi = false;
            _vm.links = '';
            _vm.links_error = '';}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }