<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="shared_drives"
      :items-per-page="15"
      class="elevation-1"
      sort-by="id"
      :sort-desc="true"
    >
      <template v-slot:top row>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <AddSharedDrive class="align-end"></AddSharedDrive>
        </v-toolbar>
      </template>
      <template v-slot:item.owner.email="{ item }">
        {{ item.owner.email }}
        <v-avatar v-if="item.owner.avatar_url" size="25">
          <img :src="item.owner.avatar_url" alt="avatar" />
        </v-avatar>
      </template>
      <template v-slot:item.enabled="{ item }">
        <v-chip v-if="item.enabled" outlined label small color="primary"
          >Yes</v-chip
        >
        <v-chip v-else outlined label small color="gray">No</v-chip>
      </template>
      <template v-slot:item.members="{ item }">
        {{ item.members.length }}
        <v-tooltip v-if="item.members.length > 0" bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab x-small left icon v-on="on">
              <v-icon color="grey lighten-1">mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <ul>
            <li v-for="account in orderBy(item.members)" :key="account.id">
              {{ account.email }}
            </li>
          </ul>
        </v-tooltip>
      </template>
      <template v-slot:item.proxy_only="{ item }">
        <span>
          <v-chip v-if="item.proxy_only" outlined label small color="primary"
            >Yes</v-chip
          >
          <v-chip v-else outlined label small color="gray">No</v-chip>
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <!-- <v-icon
          v-if="item.status != 1"
          small
          class="mr-2"
          color="warning"
          @click="edit_account(item)"
        >
          mdi-link-lock
        </v-icon> -->
        <v-icon small class="mr-2" color="info" @click="manage_members(item)">
          mdi-account-group
        </v-icon>
        <v-icon small @click="delete_shared_drive(item.id)">
          mdi-delete
        </v-icon>
        <v-dialog
          ref="confirmAcc"
          v-model="dialog"
          :max-width="options.width"
          :style="{ zIndex: options.zIndex }"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar dark :color="options.color" dense flat>
              <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click.native="agree"
                >Yes</v-btn
              >
              <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>

    <v-dialog width="900" persistent v-model="user_dialog">
      <v-card>
        <v-card-title v-if="shared_drive.owner">
          Manage Members of '{{ shared_drive.drive_name }}' -
          {{ shared_drive.owner.email }}
        </v-card-title>
        <v-form v-model="user_form">
          <v-container>
            <v-row dense no-gutters>
              <v-col align-self="start" cols="10" sm="3">
                <v-text-field
                  v-if="shared_drive"
                  v-model="shared_drive.drive_name"
                  disabled
                ></v-text-field>
                <!-- <v-combobox
                  label="Shared Drive"
                  @change="drive_name_changed"
                  v-model="drive_name"
                  :items="shared_drive_sel"
                  item-text="drive_name"
                  item-value="id"
                >
                </v-combobox> -->
              </v-col>
              <v-col cols="4"></v-col>
              <v-col align-self="end" cols="2" md="5">
                <v-text-field v-model="search_acc" label="Seach"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters dense justify="end"> </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-list dense>
                  <v-subheader>Current Accounts</v-subheader>
                  <v-list-item-group
                    v-model="shared_accounts"
                    multiple
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in shared_drive_accounts"
                      :key="i"
                      :value="item"
                    >
                      <template v-slot:default="{ active, toggle }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            :true-value="item"
                            color="deep-purple accent-4"
                            @click="toggle"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.email"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col class="text-md-center" cols="12" md="2">
                <v-btn
                  :disabled="drive_name == '' || sel_accounts.length === 0"
                  class="ma-2"
                  color="success"
                  @click="add_selected_accounts"
                >
                  <v-icon left>mdi-chevron-left</v-icon> Add
                </v-btn>
                <v-btn
                  :disabled="drive_name == '' || shared_accounts.length === 0"
                  class="ma-2"
                  color=""
                  @click="remove_selected_accounts"
                >
                  <v-icon left>mdi-chevron-right</v-icon> Remove
                </v-btn>
              </v-col>
              <v-col cols="12" md="5">
                <v-list dense class="overflow-y-auto">
                  <v-subheader>Available Accounts</v-subheader>
                  <v-list-item-group
                    v-model="sel_accounts"
                    multiple
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in eligible_google_accounts"
                      :key="i"
                      :value="item"
                    >
                      <template v-slot:default="{ active, toggle }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            :true-value="item"
                            color="deep-purple accent-4"
                            @click="toggle"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.email"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            :loading="saving"
            color="primary darken-1"
            :disabled="drive_id == -1"
            @click="save_changes"
            >Save</v-btn
          >
          <v-btn color="grey" text @click="user_dialog_close">Close</v-btn>
        </v-card-actions>
        <v-snackbar
          :color="snackbar_color"
          dark
          v-model="snackbar"
          :timeout="4000"
        >
          {{ snackbar_text }}
          <v-btn text @click="snackbar = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddSharedDrive from "@/components/AddSharedDrive";

// import EventBus from "@/utils/eventBus";

import orderBy from "lodash/orderBy";

export default {
  components: { AddSharedDrive },
  data() {
    return {
      snackbar: false,
      saving: false,
      snackbar_color: "error",
      snackbar_text: "",
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      loading: false,
      user_dialog: false,
      user_form: false,
      sel_accounts: [],
      current_accounts: [],
      drive_id: -1,
      drive_name: "",
      shared_drive: {},
      drive_members: [],
      shared_accounts: [],
      search_acc: "",
      search_drive_acc: "",
      options: {
        color: "primary",
        width: 290,
        zIndex: 200
      },
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: false
        },
        {
          text: "Drive Name",
          value: "drive_name",
          sortable: false
        },
        {
          text: "Members",
          value: "members",
          sortable: false,
          align: "left"
        },
        {
          text: "Chunks",
          value: "chunk_count",
          align: "center",
          sortable: false
        },
        {
          text: "Owner",
          value: "owner.email",
          align: "start",
          sortable: false,
          //   divider: true,
          class: ""
          //   width: "100px",
          //   filter: value => console.log(value)
          //   sort?: (a: any, b: any) => number
        },

        {
          text: "Enabled",
          value: "enabled",
          align: "center",
          sortable: false
        },

        {
          text: "Actions",
          value: "action",
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {
    shared_drives: function() {
      return this.$store.getters.shared_drives;
    },

    shared_drive_accounts: function() {
      let shared_drive = this.$store.getters.shared_drives.find(({ id }) => {
        if (!id) return;
        return id == this.drive_id;
      });
      if (!shared_drive) return [];

      return orderBy(this.drive_members, ["email"], ["asc"]);
    },
    eligible_google_accounts: function() {
      return orderBy(
        this.$store.getters.google_accounts.filter(
          function(ac) {
            return (
              ac.status == 1 &&
              ac.proxy_only &&
              !this.drive_members.find(({ id }) => {
                return ac.id === id;
              }) &&
              (this.search_acc.length > 0
                ? ac.email
                    .toLowerCase()
                    .indexOf(this.search_acc.toLowerCase()) >= 0
                : true)
            );
          }.bind(this)
        ),
        ["email"],
        ["asc"]
      );
      // .sort(function(a, b) {
      //   return a.email == b.email ? 0 : a.email > b.email ? 1 : -1;
      // });
    }
  },
  methods: {
    orderBy(items) {
      return orderBy(items, ["email"], ["asc"]);
    },
    dialog_close() {
      this.user_dialog = false;
      this.drive_members = [];
      this.sel_accounts = [];
      this.shared_accounts = [];
      this.drive_name = "";
      this.drive_id = -1;
      this.saving = false;
      this.shared_drive = {};
    },
    user_dialog_close() {
      this.dialog_close();
    },
    save_changes() {
      // this.resolve(true);
      if (this.drive_id == -1) return;
      // console.log(this.drive_members);
      // console.log(this.drive_id);

      const shared_drive = this.$store.getters.shared_drives.find(({ id }) => {
        return id === this.drive_id;
      });
      this.saving = true;
      this.$store
        .dispatch("add_shared_drive_members", {
          drive: shared_drive,
          accounts: this.drive_members
        })
        .then(() => this.dialog_close())
        .catch(error => {
          let err_text = "Couldn't update the members";
          if (error.response.status >= 500) {
            err_text = error.response.error.response.data.error;
          } else if (error.response.data.error) {
            err_text = error.response.data.error;
          } else {
            err_text = error.response.statusText;
          }
          this.snackbar_text = err_text;
          this.snackbar = true;
        })
        .finally(() => (this.saving = false));
    },

    remove_selected_accounts() {
      // const shared_drive = this.$store.getters.shared_drives.find(drive => {
      //   return drive.id === this.drive_id;
      // });

      let shared_accounts = Object.assign([], this.shared_accounts);

      this.shared_accounts = shared_accounts.map(item => {
        let idx = shared_accounts.indexOf(item);
        // console.log([idx, item]);
        this.drive_members.pop(item);
        this.shared_accounts.splice(idx, 1);
      });

      this.shared_accounts = [];
    },
    add_selected_accounts() {
      const shared_drive = this.$store.getters.shared_drives.find(drive => {
        return drive.id === this.drive_id;
      });

      // const indexes = [];
      // console.log(this.sel_accounts);
      let sel_accounts = Object.assign([], this.sel_accounts);

      this.sel_accounts = sel_accounts.map(item => {
        let idx = sel_accounts.indexOf(item);
        this.drive_members.push(item);

        this.sel_accounts.splice(idx, 1);
      });

      // indexes.forEach(i => {
      //   console.log(i);
      //   this.sel_accounts.splice(i, 1);
      // });
      this.sel_accounts = this.sel_accounts = [];
      shared_drive;
      // this.$store
      //   .dispatch("add_shared_drive_member", {
      //     drive: shared_drive,
      //     accounts: this.sel_accounts
      //   })
      //   .then(() => (this.sel_accounts = []));
    },
    drive_name_changed(item) {
      if (!item || typeof item != "object") {
        this.drive_name = "";
        this.drive_id = -1;
        return;
      }
      this.drive_id = item.id;
      this.drive_name = item.drive_name;
      // console.log(item);
      this.drive_members = Object.assign([], item.members);
      // console.log(this.shared_drive);
    },
    manage_members(item) {
      this.drive_id = item.id;
      this.drive_name = item.drive_name;
      this.user_dialog = true;
      this.shared_drive = item;
      this.drive_members = Object.assign([], item.members);
    },
    delete_shared_drive(itemid) {
      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm)
          this.$store
            .dispatch("delete_shared_drive", itemid)
            .then(this.$store.dispatch("fetch_shared_drives"));
      });
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    // filter: value => console.log(value),
    getColor(status) {
      if (status == 0) return "warning";
      else if (status == 1) return "success";
      else if (status == 2) return "error";
      else return "gray";
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("fetch_google_accounts").then(() => {
      // this.loading = false;
      this.$store.dispatch("fetch_shared_drives").then(() => {
        this.loading = false;
      });
    });
  }
};
</script>
