<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="media_files"
      :items-per-page="15"
      v-model="selected"
      show-select
      class="elevation-1"
      :sort-desc="true"
      sort-by="created_date"
      :server-items-length="totalMediaFiles"
      :options.sync="table_options"
      :search="search"
      :footer-props="{
        'show-current-page': true,
        'show-first-last-page': false,
        'items-per-page-options': [5, 10, 15, -1]
      }"
    >
      <template v-slot:header.file_name="{ header }">
        <span>{{ header.text }} </span>

        <span>
          <v-btn
            small
            @click="delete_files_multi"
            :disabled="selected.length <= 0"
            icon
            color="warning"
            ><v-icon>mdi-delete-outline</v-icon></v-btn
          >
        </span>
      </template>

      <template v-slot:item.created_date="{ item }">
        <span>{{ new Date(item.created_date).toLocaleString() }}</span>
      </template>

      <template v-slot:top row>
        <v-toolbar flat color="white">
          <v-toolbar-title>File List </v-toolbar-title>
          <v-col cols="4">
            <v-text-field
              class="ml-10"
              v-model="search"
              append-icon="mdi-magnify"
              label="Name or ID"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-toolbar>
        <v-dialog
          v-model="edit_dialog"
          persistent
          :overlay="false"
          transition="dialog-transition"
          width="660"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Edit File</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-text-field
                    v-model="edited_item.file_name"
                    label="File name"
                  ></v-text-field>
                </v-row>

                <!-- <v-row>
                  <v-divider></v-divider>
                </v-row> -->
                <v-row class="justify-center">
                  <v-card width="100%">
                    <v-card-title>
                      Subtitles
                      <v-spacer></v-spacer>
                      <!-- <v-btn
                        icon
                        transition="fade-transition"
                        v-if="!expand"
                        color="primary"
                        text
                        small
                        @click="expand = true"
                        ><v-icon>mdi-arrow-down-drop-circle</v-icon></v-btn
                      > -->
                      <v-icon v-if="!expand" @click="expand_sub" color="primary"
                        >mdi-arrow-down-drop-circle</v-icon
                      >
                      <!-- <v-btn
                        icon
                        transition="fade-transition"
                        v-else
                        text
                        small
                        @click="expand = false"
                        ><v-icon>mdi-arrow-up-drop-circle</v-icon></v-btn
                      > -->
                      <v-icon v-else @click="expand = false"
                        >mdi-arrow-up-drop-circle</v-icon
                      >
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text v-show="expand">
                        <v-row>
                          <v-col cols="2">
                            <v-text-field label="Label" v-model="sub_label">
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field label="URL" v-model="sub_url">
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              class="ma-2"
                              outlined
                              color="success"
                              @click="add_sub"
                              >Add</v-btn
                            >
                          </v-col>
                        </v-row>

                        <v-progress-linear
                          v-if="loading_subs"
                          indeterminate
                        ></v-progress-linear>

                        <v-row v-for="(item, i) in get_subtitles" :key="i">
                          <v-col cols="2">
                            <v-text-field
                              class="capitalize"
                              v-model="item.label"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field v-model="item.url"> </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-icon class="ml-2" small @click="edit_sub(item)">
                              mdi-content-save
                            </v-icon>

                            <v-icon class="" small @click="delete_sub(item)">
                              mdi-delete
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close_edit"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.file_name="{ item }">
        <span>{{ item.file_name }}</span>
        <v-icon v-if="item.original" right color="success"
          >mdi-google-drive</v-icon
        >
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label small :color="getColor(item.status)">{{
          item.status_text
        }}</v-chip>
        <v-tooltip bottom v-if="item.status == 2">
          <template v-slot:activator="{ on }">
            <v-btn fab x-small left icon v-on="on">
              <v-icon color="grey lighten-1">mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ item.error }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.links="{ item }">
        <!-- ${this.API_BASE}/api/media?hash=b4786afd0551cb67fa3ca5a8bb75c77a -->
        <!-- <v-btn
          small
          color="success"
          :href="'${this.API_BASE}/media/' + item.file_hash + '.m3u8'"
          target="_blank"
          class="mr-2"
          >m3u8</v-btn
        > -->
        <!-- <v-btn
          small
          color="success"
          :href="
            '${this.API_BASE}/media/master/' + item.file_hash + '.m3u8'
          "
          target="_blank"
          class=""
          >master</v-btn
        > -->
        <v-btn
          small
          color="success"
          :href="`${API_BASE}/media/watch/` + item.file_hash"
          target="_blank"
          >Watch</v-btn
        >
        <v-btn
          icon
          color="success"
          :href="
            `https://drive.google.com/file/d/${item.original_file_id}/view `
          "
          target="_blank"
          ><v-icon color="success">mdi-google-drive</v-icon></v-btn
        >
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon small @click="edit_file(item)">
          mdi-pencil
        </v-icon>

        <v-icon small @click="delete_media(item.id)">
          mdi-delete
        </v-icon>
        <v-dialog
          ref="confirmFile"
          v-model="dialog"
          :max-width="options.width"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar dark :color="options.color" flat>
              <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click.native="agree"
                >Yes</v-btn
              >
              <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import EventBus from "@/utils/eventBus";
import axios from "axios";

import debounce from "lodash/debounce";

//const API_BASE = process.env.VUE_APP_API_BASE

export default {
  // components: { AddFile },
  data() {
    return {
      API_BASE: process.env.VUE_APP_API_BASE,
      totalMediaFiles: 0,
      expand: false,
      edit_dialog: false,
      edited_index: -1,
      edited_item: {
        file_name: "",
        subtitles: []
      },
      default_item: {
        file_name: "",
        subtitles: []
      },
      sub_label: "",
      sub_url: "",
      loading: false,
      loading_subs: false,
      subtitles: [],
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      search: "",
      selected: [],
      options: {
        color: "primary",
        width: 290,
        zIndex: 200
      },
      table_options: {},
      headers: [
        {
          text: "File Name",
          value: "file_name",
          align: "start",
          sortable: false,
          //   divider: true,
          class: ""
          //   width: "100px",
          //   filter: value => console.log(value)
          //   sort?: (a: any, b: any) => number
        },
        {
          text: "Resolution",
          value: "video_res",
          sortable: false,
          align: "center"
        },
        // {
        //   text: "Backups",
        //   value: "backups",
        //   sortable: false,
        //   align: "center",
        //   width: "50px"
        // },
        {
          text: "Links",
          value: "links",
          sortable: false,
          width: "180px"
        },
        {
          text: "Created Date",
          value: "created_date",
          sortable: false,
          width: "185px"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  watch: {
    table_options: {
      handler() {
        this.request_media_files();
      },
      deep: true
    },
    search: {
      handler() {
        this.debounsedSearch();
      },
      deep: true
    }
  },
  computed: {
    media_files: function() {
      return this.$store.getters.media_files;
    },
    get_subtitles: function() {
      return this.subtitles;
    }
  },
  created: function() {
    this.debounsedSearch = debounce(this.search_media_files, 500);
    EventBus.$on("MEDIA_FILE_ADD", () => {
      this.request_media_files();
    });
  },
  methods: {
    delete_files_multi() {
      this.open("Delete", `Removing ${this.selected.length}. Are you sure?`, {
        color: "red"
      }).then(confirm => {
        if (confirm && this.selected)
          this.$store
            .dispatch("delete_media_multi", this.selected.map(item => item.id))
            .then(() => {
              this.selected = [];
              this.request_media_files();
            });
      });
    },
    search_media_files() {
      if (this.search.length > 1 || this.search === "") {
        this.request_media_files();
      }
    },
    expand_sub() {
      this.expand = true;
      this.loading_subs = true;
      this.subtitles = [];
      axios
        .get(
          `${this.API_BASE}/api/subtitles/` + this.edited_item.original_file_id
        )
        .then(resp => {
          this.subtitles = resp.data;
        })
        .finally(() => {
          this.loading_subs = false;
        });
    },
    edit_sub(item) {
      // console.log(item);
      axios
        .post(
          `${this.API_BASE}/api/subtitles/edit/` +
            this.edited_item.original_file_id,
          item
        )
        .then(() => {
          // console.log(resp.data);
          // this.sub_label = this.sub_url = "";
          // this.subtitles.push(resp.data);
        })
        .catch(() => "failed");
    },
    add_sub() {
      if (this.edited_index == -1) return;

      let subtitle = {
        label: this.sub_label,
        url: this.sub_url
      };
      axios
        .post(
          `${this.API_BASE}/api/subtitles/add/` +
            this.edited_item.original_file_id,
          subtitle
        )
        .then(resp => {
          //console.log(resp.data);
          this.sub_label = this.sub_url = "";
          this.subtitles.push(resp.data);
        })
        .catch(() => "failed");
    },
    edit_file(item) {
      this.edited_index = this.media_files.indexOf(item);
      this.edited_item = Object.assign({}, item);
      // console.log(this.edited_item);
      this.edit_dialog = true;
    },
    close_edit() {
      this.edit_dialog = false;
      this.sub_label = this.sub_url = "";
      this.expand = false;
      setTimeout(() => {
        this.edited_item = Object.assign({}, this.default_item);
        this.edited_index = -1;
        this.subtitles = [];
      }, 300);
    },
    save_edit() {
      // console.log(file_id);
    },
    delete_sub(item) {
      // console.log(itemid);

      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm) {
          if (this.edited_index == -1) return;
          axios
            .post(
              `${this.API_BASE}/api/subtitles/delete/` +
                this.edited_item.original_file_id,
              {
                id: item.id
              }
            )
            .then(() => {
              let idx = this.subtitles.indexOf(item);
              this.subtitles.splice(idx, 1);
            });
        }
      });
    },
    delete_media(itemid) {
      // console.log(itemid);
      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm)
          this.$store
            .dispatch("delete_media", itemid)
            .then(() => this.request_media_files());
      });
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    // filter: value => console.log(value),
    getColor(status) {
      if (status == 0) return "warning";
      else if (status == 1) return "success";
      else if (status == 2) return "error";
      else return "gray";
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    request_media_files() {
      this.loading = true;

      const { page, itemsPerPage } = this.table_options;

      this.$store
        .dispatch("fetch_media_files", {
          page,
          itemsPerPage,
          search: this.search
        })
        .then(count => (this.totalMediaFiles = count))
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
