<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="google_accounts"
      :items-per-page="15"
      class="elevation-1"
      sort-by="id"
      :sort-desc="true"
    >
      <template v-slot:top row>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <AddGoogleAccount class="align-end"></AddGoogleAccount>
          <ImportGoogleAccounts> </ImportGoogleAccounts>
        </v-toolbar>
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.email }}
        <v-avatar v-if="item.avatar_url" size="25">
          <img :src="item.avatar_url" alt="avatar" />
        </v-avatar>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label small :color="getColor(item.status)">{{
          item.status_text
        }}</v-chip>
        <v-tooltip bottom v-if="item.status == 2">
          <template v-slot:activator="{ on }">
            <v-btn fab x-small left icon v-on="on">
              <v-icon color="grey lighten-1">mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ item.error }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.can_upload="{ item }">
        <span v-if="item.can_upload">
          <v-chip outlined label small color="primary">Yes</v-chip>
        </span>
        <span v-else>
          <v-chip outlined label small color="gray">No</v-chip>
        </span>
      </template>
      <template v-slot:item.proxy_only="{ item }">
        <span>
          <v-chip v-if="item.proxy_only" outlined label small color="primary"
            >Yes</v-chip
          >
          <v-chip v-else outlined label small color="gray">No</v-chip>
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          v-if="item.status != 1"
          small
          class="mr-2"
          color="warning"
          @click="edit_account(item)"
        >
          mdi-link-lock
        </v-icon>
        <v-icon small @click="delete_account(item.id)">
          mdi-delete
        </v-icon>
        <v-dialog
          ref="confirmAcc"
          v-model="dialog"
          :max-width="options.width"
          :style="{ zIndex: options.zIndex }"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar dark :color="options.color" dense flat>
              <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click.native="agree"
                >Yes</v-btn
              >
              <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AddGoogleAccount from "@/components/AddGoogleAccount";
import ImportGoogleAccounts from "@/components/ImportGoogleAccounts";
import EventBus from "@/utils/eventBus";

export default {
  components: { AddGoogleAccount, ImportGoogleAccounts },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      loading: false,
      options: {
        color: "primary",
        width: 290,
        zIndex: 200
      },
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: false
        },
        {
          text: "Email",
          value: "email",
          align: "start",
          sortable: false,
          //   divider: true,
          class: ""
          //   width: "100px",
          //   filter: value => console.log(value)
          //   sort?: (a: any, b: any) => number
        },
        {
          text: "Folder Name",
          value: "folder_name",
          align: "center",
          sortable: false
        },
        {
          text: "Chunks",
          value: "chunk_count",
          sortable: false,
          align: "center"
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false
        },
        {
          text: "Can Upload",
          value: "can_upload",
          sortable: false,
          align: "center"
        },
        {
          text: "Proxy Only",
          value: "proxy_only",
          sortable: false,
          align: "center"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {
    google_accounts: function() {
      return this.$store.getters.google_accounts;
    }
  },
  methods: {
    edit_account(item) {
      this.$http({
        url: `${process.env.VUE_APP_API_BASE}/api/account/reauth`,
        data: {
          email: item.email
        },
        method: "POST"
      })
        .then(response => {
          if (!response.data.auth) {
            this.$EventBus.$emit("SNACKBAR", {
              color: "error",
              message: response.data.error
            });
            return;
          }
          EventBus.$emit("GOOGLE_AC_AUTH", response.data.redirect);
        })
        .catch(err => {
          this.$EventBus.$emit("SNACKBAR", {
            color: "error",
            message: err.message
          });
        });
    },
    delete_account(itemid) {
      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm) this.$store.dispatch("delete_google_account", itemid);
      });
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    // filter: value => console.log(value),
    getColor(status) {
      if (status == 0) return "warning";
      else if (status == 1) return "success";
      else if (status == 2) return "error";
      else return "gray";
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("fetch_google_accounts")
      .finally(() => (this.loading = false));
  }
};
</script>
