<template>
  <v-app
    ><router-view></router-view>
    <v-icon>mdi-view-dashboard</v-icon>
  </v-app>
</template>

<script>
export default {
  created: function() {
    // console.log(this.$route.query);
  }
};
</script>
