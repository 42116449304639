var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.google_accounts,"items-per-page":15,"sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('AddGoogleAccount',{staticClass:"align-end"}),_c('ImportGoogleAccounts')],1)]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.email)+"\n      "),(item.avatar_url)?_c('v-avatar',{attrs:{"size":"25"}},[_c('img',{attrs:{"src":item.avatar_url,"alt":"avatar"}})]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":_vm.getColor(item.status)}},[_vm._v(_vm._s(item.status_text))]),(item.status == 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","left":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_vm._e()]}},{key:"item.can_upload",fn:function(ref){
var item = ref.item;
return [(item.can_upload)?_c('span',[_c('v-chip',{attrs:{"outlined":"","label":"","small":"","color":"primary"}},[_vm._v("Yes")])],1):_c('span',[_c('v-chip',{attrs:{"outlined":"","label":"","small":"","color":"gray"}},[_vm._v("No")])],1)]}},{key:"item.proxy_only",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.proxy_only)?_c('v-chip',{attrs:{"outlined":"","label":"","small":"","color":"primary"}},[_vm._v("Yes")]):_c('v-chip',{attrs:{"outlined":"","label":"","small":"","color":"gray"}},[_vm._v("No")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status != 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.edit_account(item)}}},[_vm._v("\n        mdi-link-lock\n      ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.delete_account(item.id)}}},[_vm._v("\n        mdi-delete\n      ")]),_c('v-dialog',{ref:"confirmAcc",style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.options.color,"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}]},[_vm._v(_vm._s(_vm.message))]),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},nativeOn:{"click":function($event){return _vm.agree($event)}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel($event)}}},[_vm._v("Cancel")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }