<template>
  <div>
    <v-card class="mb-5">
      <v-card-title>
        Header
        <v-spacer></v-spacer>
        <v-switch
          color="success"
          label="Active"
          v-model="ads['0'].active"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-textarea label="AD Code" v-model="ads['0'].content"> </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading['0']" @click="save_ad(0)" text color="success"
          >Save</v-btn
        ></v-card-actions
      >
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        IFrame
        <v-spacer></v-spacer>
        <v-switch
          color="success"
          label="Active"
          v-model="ads['1'].active"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-textarea label="iframe link" v-model="ads['1'].content">
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading['1']" @click="save_ad(1)" text color="success"
          >Save</v-btn
        ></v-card-actions
      >
    </v-card>
    <v-card class="mb-5">
      <v-card-title>
        VAST
        <v-spacer></v-spacer>
        <v-switch
          color="success"
          label="Active"
          v-model="ads['2'].active"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-textarea label="VAST URL" v-model="ads['2'].content"> </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading['2']" @click="save_ad(2)" text color="success"
          >Save</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
// import AddGoogleAccount from "@/components/AddGoogleAccount";

export default {
  //   components: { AddGoogleAccount },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      loading: {
        "0": false,
        "1": false,
        "2": false
      }
    };
  },
  computed: {
    google_accounts: function() {
      return this.$store.getters.google_accounts;
    },
    ads: function() {
      return this.$store.getters.ads;
    }
  },
  //   watch: {
  //     ads: {
  //       handler(val, oldVal) {
  //         console.log("ads", val, oldVal);
  //       },
  //       deep: true
  //     }
  //   },
  methods: {
    save_ad(idx) {
      this.loading[idx] = true;
      this.$store
        .dispatch("save_ad", idx)
        .finally(() => (this.loading[idx] = false));
    }
  },
  mounted() {
    this.loading = {
      "0": true,
      "1": true,
      "2": true
    };
    this.$store.dispatch("fetch_ads").finally(
      () =>
        (this.loading = {
          "0": false,
          "1": false,
          "2": false
        })
    );
  }
};
</script>
