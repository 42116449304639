<i18n src="../locales/en.json"></i18n>
<i18n>
{"ja": {
    "messagebtn": "こんにちは、世界！"
  },
  "en": {
    "messagebtn": "hello world!"
  }
}
</i18n>

<i18n>
{
  "en": {
    "hello": "hello world!"
  }

}
</i18n>

<template>
  <div>
    <!-- <v-btn
      href="https://accounts.google.com/o/oauth2/v2/auth?
 scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file&
 access_type=offline&
 include_granted_scopes=true&
 state=state_parameter_passthrough_value&
 redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fcb&
 response_type=code&
 client_id=208959886424-lq1nks42jo23057g7c3f2cm4ifjcgqe1.apps.googleusercontent.com"
      color="success darken-1"
      dark
      class="ma-2"
      ><v-icon left>mdi-account-plus</v-icon> Add Account</v-btn
    > -->

    <!-- <v-btn color="orange darken-1" dark class="ma-2" v-on:click.prevent="on">
      {{ $t("messagebtn") }}</v-btn
    > -->

    <v-snackbar
      v-model="snackbar"
      :timeout="2500"
      :color="color"
      :top="false"
      :right="true"
      dense
    >
      {{ text }}

      <v-btn text @click="snackbar = false">
        dismiss
      </v-btn>
    </v-snackbar>

    <v-tabs>
      <v-tab>Files</v-tab>
      <v-tab>Shared Drives</v-tab>
      <v-tab>Google Accounts</v-tab>

      <v-tab>Google Apps</v-tab>
      <v-tab-item>
        <ListQue></ListQue>
      </v-tab-item>
      <v-tab-item>
        <ListSharedDrives></ListSharedDrives>
      </v-tab-item>
      <v-tab-item>
        <ListGoogleAccounts></ListGoogleAccounts>
      </v-tab-item>

      <v-tab-item>
        <ListGoogleApps></ListGoogleApps>
      </v-tab-item>
      <v-tab>Ads</v-tab>
      <v-tab-item><ListAds></ListAds> </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
// import EventBus from "@/utils/eventBus";
// import AddGoogle from "@/components/AddGoogleAccount";
import ListGoogleApps from "@/components/ListGoogleApps";
import ListGoogleAccounts from "@/components/ListGoogleAccounts";
import ListSharedDrives from "@/components/ListSharedDrives";
import ListQue from "@/components/ListQue";
import ListAds from "@/components/ListAds";

export default {
  name: "app",
  components: {
    ListSharedDrives,
    ListGoogleAccounts,
    ListQue,
    ListGoogleApps,
    ListAds
  },
  data() {
    return {
      locale: "en",
      snackbar: false,
      color: "success",
      text: "Authentication Successfull",
      e1: 0,
      value: 10,
      dialog: false,
      status: ""
    };
  },

  computed: {
    headers() {
      return [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name'
        // },
        { text: "Email", value: "email" },
        { text: "Folder ID", value: "fid" },
        { text: "Status", value: "status", align: "center" },
        {
          text: "Auth. Status",
          align: "center",
          value: "auth_status",
          filter: value => {
            // console.log(value)
            if (!this.status) return true;
            return value === this.status;
          }
        },
        { text: "Actions", value: "actions", align: "center" }
      ];
    }
  },
  methods: {
    getColor(status) {
      if (status.toLowerCase() === "active" || status.toLowerCase() === "done")
        return "green";
      else if (status === "Pending") return "orange";
      else return "red";
    },
    filter(status) {
      // console.log(status)
      this.status = status;
    },
    on() {
      //   console.log("on");
      this.$i18n.locale = "ja";
      this.snackbar = true;
    },
    fetch() {
      this.$store
        .dispatch("fetch", {})
        .then(() => {
          // console.log(args.data);
          this.color = "success";
          this.text = "Fetch success";
          this.snackbar = true;
        })
        .catch(err => {
          if (err.response.status == 401) {
            this.$store.dispatch("logout");
            // if (this.rftoken) {
            //   console.log("do refresh");
            // } else {
            // EventBus.$emit(
            //   "SHOW_INFO",
            //   "You have been signed out. Please sign in to continue"
            // );
            // this.$router.push("login").catch(err => console.log(err));
            // }\
            // console.log(err.response.status);
          }

          this.color = "error";
          this.text = "Fetch Failed";
          this.snackbar = true;
        });
    }
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
    }
  }
};
</script>
