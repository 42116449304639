<template>
  <div>
    <v-dialog width="660" v-model="dialog" persistent
      ><template v-slot:activator="{ on }">
        <v-btn color="success" class="ma-2" dark v-on="on"
          ><v-icon left>mdi-api</v-icon> Add App</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          Add New Google App
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Client ID *"
                    required
                    v-model="client_id"
                    :error-messages="client_id_error"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Client Secret *"
                    required
                    v-model="client_secret"
                    :error-messages="client_secret_error"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="API Key *"
                    required
                    v-model="api_key"
                    :error-messages="api_key_error"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Redirect URL *"
                    required
                    v-model="redirect_url"
                    :error-messages="redirect_url_error"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Token URL *"
                    required
                    v-model="token_url"
                    :error-messages="token_url_error"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    v-model="proxy_only"
                    label="For proxy only"
                    color="blue darken-1"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="add_app">
            Add
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventBus from "@/utils/eventBus";

export default {
  data() {
    return {
      client_id: "",
      client_secret: "",
      api_key: "",
      redirect_url: `${process.env.VUE_APP_API_BASE}/api/google_auth`,
      token_url: "https://www.googleapis.com/oauth2/v4/token",
      client_id_error: "",
      client_secret_error: "",
      redirect_url_error: "",
      token_url_error: "",
      api_key_error: "",
      account_errors: [],
      dialog: false,
      is_private: false,
      account_id: false,
      proxy_only: false,
      required: value => !!value || "Required."
    };
  },
  watch: {},
  computed: {},

  methods: {
    add_app() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let app_info = {
        client_id: this.client_id,
        client_secret: this.client_secret,
        api_key: this.api_key,
        redirect_url: this.redirect_url,
        token_url: this.token_url,
        proxy_only: this.proxy_only
      };
      // console.log(app_info);
      this.$store
        .dispatch("add_app", app_info)
        .then(() => {
          this.dialog = false;
        })
        .catch(err => {
          if (err.response) {
            // console.log(err.response);
            if (err.response.status >= 500) {
              EventBus.$emit("SNACKBAR", {
                color: "error",
                message: "Server Error"
              });
              //   console.log("snackbar");
            } else if (err.response.data.error) {
              this.api_key_error = err.response.data.error;
            }
          } else if (err.request) {
            err.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            "Error", err.message;
          }
        });
    }
  }
};
</script>
