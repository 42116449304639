<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="google_apps"
      :items-per-page="15"
      class="elevation-1"
      sort-by="id"
      :sort-desc="true"
    >
      <template v-slot:top row>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <AddGoogleApp class="align-end"></AddGoogleApp>
        </v-toolbar>
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.email }}
        <v-avatar size="25">
          <img :src="item.avatar_url" alt="avatar" />
        </v-avatar>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label small :color="getColor(item.status)">{{
          item.status_text
        }}</v-chip>
        <v-tooltip bottom v-if="item.status == 2">
          <template v-slot:activator="{ on }">
            <v-btn fab x-small left icon v-on="on">
              <v-icon color="grey lighten-1">mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ item.error }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.proxy_only="{ item }">
        <span>
          <v-chip v-if="item.proxy_only" outlined label small color="primary"
            >Yes</v-chip
          >
          <v-chip v-else outlined label small color="gray">No</v-chip>
        </span>
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.ac_count }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="edit_app(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="delete_account(item.id)">
          mdi-delete
        </v-icon>
        <v-dialog
          ref="confirmApp"
          v-model="dialog"
          :max-width="options.width"
          :style="{ zIndex: options.zIndex }"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar dark :color="options.color" dense flat>
              <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click.native="agree"
                >Yes</v-btn
              >
              <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="660" v-model="edit_dialog">
          <v-card>
            <v-card-title>
              Edit Google App
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Client ID *"
                        required
                        v-model="edited_item.client_id"
                        :error-messages="client_id_error"
                        :rules="[required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Client Secret *"
                        required
                        v-model="edited_item.client_secret"
                        :error-messages="client_secret_error"
                        :rules="[required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="API Key *"
                        required
                        v-model="edited_item.api_key"
                        :error-messages="api_key_error"
                        :rules="[required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Redirect URL *"
                        required
                        v-model="edited_item.redirect_url"
                        :error-messages="redirect_url_error"
                        :rules="[required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Token URL *"
                        required
                        v-model="edited_item.token_uri"
                        :error-messages="token_url_error"
                        :rules="[required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-switch
                        v-model="edited_item.proxy_only"
                        label="For proxy only"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="save_app">
                Save
              </v-btn>
              <v-btn color="blue darken-1" text @click="close_edit"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AddGoogleApp from "@/components/AddGoogleApp";
// import EventBus from "@/utils/eventBus";

export default {
  components: { AddGoogleApp },
  data() {
    return {
      client_id: "",
      client_secret: "",
      api_key: "",
      redirect_url: `${process.env.VUE_APP_API_BASE}/api/google_auth`,
      token_url: "https://www.googleapis.com/oauth2/v4/token",
      proxy_only: false,
      client_id_error: "",
      client_secret_error: "",
      redirect_url_error: "",
      token_url_error: "",
      api_key_error: "",
      edit_dialog: false,
      loading: false,
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      required: value => !!value || "Required.",
      options: {
        color: "primary",
        width: 290,
        zIndex: 200
      },
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: false
        },
        {
          text: "Client ID",
          value: "client_id",
          sortable: false
        },
        {
          text: "API Key",
          value: "api_key",
          sortable: false
        },
        {
          text: "Redirect URL",
          value: "redirect_url",
          sortable: false
        },
        {
          text: "Proxy Only",
          value: "proxy_only",
          sortable: false
        },
        {
          text: "Accounts",
          value: "ac_count",
          align: "right",
          sortable: false
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],
      edited_index: -1,
      edited_item: {},
      defaults: {}
    };
  },
  computed: {
    google_apps: function() {
      return this.$store.getters.google_apps;
    }
  },

  methods: {
    edit_app(item) {
      this.edited_index = this.google_apps.indexOf(item);
      this.edited_item = Object.assign({}, item);
      // console.log(this.edited_item);
      this.edit_dialog = true;
    },
    save_app() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let app_info = {
        app_id: this.edited_item.id,
        client_id: this.edited_item.client_id,
        client_secret: this.edited_item.client_secret,
        api_key: this.edited_item.api_key,
        redirect_url: this.edited_item.redirect_url,
        token_url: this.edited_item.token_uri,
        proxy_only: this.edited_item.proxy_only
      };

      this.$store
        .dispatch("edit_app", app_info)
        .then(() => this.close_edit())
        .catch(err => {
          if (err.response) {
            // console.log(err.response);
            if (err.response.status >= 500) {
              this.$EventBus.$emit("SNACKBAR", {
                color: "error",
                message: "Server Error"
              });
              //   console.log("snackbar");
            } else if (err.response.data.error) {
              this.api_key_error = err.response.data.error;
            }
          } else if (err.request) {
            err.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            "Error", err.message;
          }
        });
    },
    close_edit() {
      this.edit_dialog = false;
      // this.sub_label = this.sub_url = "";
      // this.expand = false;
      setTimeout(() => {
        this.edited_item = Object.assign({}, this.default_item);
        this.edited_index = -1;
      }, 300);
    },
    delete_account(item_id) {
      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm)
          this.$store.dispatch("delete_app", item_id).then(() => {
            this.$EventBus.$emit("SNACKBAR", {
              color: "success",
              message: "Removed"
            });
          });
      });
    },
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    // filter: value => console.log(value),
    getColor(status) {
      if (status == 0) return "warning";
      else if (status == 1) return "success";
      else if (status == 2) return "error";
      else return "gray";
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },
  mounted() {
    this.$EventBus.$on("GOOGLE_APP_ADD", () => {
      this.$store.dispatch("fetch_google_apps");
    });
    this.loading = true;
    this.$store
      .dispatch("fetch_google_apps")
      .finally(() => (this.loading = false));
  }
};
</script>
