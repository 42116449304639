<template>
  <div>
    <v-tabs v-model="active_tab">
      <!-- <v-tab><v-icon color="success">mdi-google-drive</v-icon></v-tab> -->
      <v-tab><v-icon color="blue">mdi-dropbox</v-icon></v-tab>
      <v-tab-item>
        <v-data-table
          id="listQueue"
          v-model="selected"
          show-select
          :loading="loading"
          :headers="headers"
          :items="queued_files"
          :items-per-page="5"
          class="elevation-1"
          no-data-text="Queue is empty"
          sort-by="id"
          :sort-desc="true"
          :server-items-length="totalQueuedFiles"
          :options.sync="table_options"
          :page.sync="page"
          :search="search"
          @page-count="pageCount = $event"
          :footer-props="{
            'show-current-page': true,
            'show-first-last-page': false,
            'items-per-page-options': [5, 10, 15, 20, -1]
          }"
        >
          <template v-slot:header.file_url="{ header }">
            <span>{{ header.text }} </span>

            <span>
              <v-btn
                small
                @click="delete_files_multi"
                :disabled="selected.length <= 0"
                icon
                color="warning"
                ><v-icon>mdi-delete-outline</v-icon></v-btn
              >

              <v-btn
                small
                @click="retry_files_multi"
                :disabled="selected.length <= 0"
                icon
                color="warning"
                ><v-icon>mdi-redo</v-icon></v-btn
              >
            </span>
          </template>

          <template v-slot:top row>
            <v-toolbar flat color="white">
              <v-toolbar-title>File Queue</v-toolbar-title>

              <v-col cols="4">
                <v-text-field
                  class="ml-10"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Name or ID"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2"
                ><v-select
                  class="mt-5"
                  clearable
                  single-line
                  small-chips
                  v-model="status"
                  :items="filters"
                  label="Status"
                  type="number"
                >
                  <v-icon slot="append">mdi-filter-menu</v-icon>
                </v-select></v-col
              >

              <v-spacer></v-spacer>
              <AddFile class="align-end"></AddFile>
            </v-toolbar>
          </template>
          <template v-slot:item.file_url="{ item }">
            <a :href="item.file_url" target="_blank">
              <span v-if="item.file_title">{{ item.file_title }}</span>
              <span v-else>{{ item.file_url }}</span>
            </a>
          </template>
          <template v-slot:item.google_account="{ item }">
            <v-layout justify-center>
              <span v-if="item.google_account">
                <v-avatar size="25">
                  <img
                    :src="item.google_account.avatar_url"
                    alt="avatar"
                    :title="item.google_account.email"
                  />
                </v-avatar>
              </span>
              <span v-else>
                <v-chip outlined label small color="gray">No</v-chip>
              </span>
            </v-layout>
          </template>
          <template v-slot:item.status="{ item }">
            <v-layout justify-center>
              <v-chip label small :color="getColor(item.status)">{{
                item.status_text
              }}</v-chip>
              <!-- <v-tooltip bottom v-if="item.status == 3">
            <template v-slot:activator="{ on }">
              <v-btn fab x-small left icon v-on="on">
                <v-icon color="grey lighten-1">mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            {{ item.error_message }}
          </v-tooltip> -->
            </v-layout>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small @click="retry_task(item.id)" class="mr-2">
              mdi-redo
            </v-icon>
            <v-icon small @click="delete_queued_item(item.id)">
              mdi-delete
            </v-icon>
            <v-dialog
              ref="confirmQueue"
              v-model="dialog"
              :max-width="options.width"
              @keydown.esc="cancel"
            >
              <v-card>
                <v-toolbar dark :color="options.color" flat>
                  <v-toolbar-title class="">{{ title }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text v-show="!!message">{{ message }}</v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn color="primary darken-1" text @click.native="agree"
                    >Yes</v-btn
                  >
                  <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
            circle
            v-model="page"
            :length="pageCount"
            :total-visible="17"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <v-divider class="my-5"></v-divider>
          <ListFiles></ListFiles>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          id="listDBQueue"
          v-model="selected_db"
          show-select
          :loading="loading_db"
          :headers="headers_db"
          :items="queued_db_files"
          :items-per-page="5"
          class="elevation-1"
          no-data-text="Queue is empty"
          sort-by="id"
          :sort-desc="true"
          :server-items-length="totalQueuedDBFiles"
          :options.sync="db_table_options"
          :page.sync="db_page"
          :search="db_search"
          @page-count="db_pageCount = $event"
          :footer-props="{
            'show-current-page': true,
            'show-first-last-page': false,
            'items-per-page-options': [5, 10, 15, 20, -1]
          }"
        >
          <template v-slot:header.file_url="{ header }">
            <span>{{ header.text }} </span>

            <span>
              <v-btn
                small
                @click="delete_db_files_multi"
                :disabled="selected_db.length <= 0"
                icon
                color="warning"
                ><v-icon>mdi-delete-outline</v-icon></v-btn
              >

              <v-btn
                small
                @click="retry_db_files_multi"
                :disabled="selected_db.length <= 0"
                icon
                color="warning"
                ><v-icon>mdi-redo</v-icon></v-btn
              >
            </span>
          </template>

          <template v-slot:top row>
            <v-toolbar flat color="white">
              <v-toolbar-title>File Queue</v-toolbar-title>

              <v-col cols="4">
                <v-text-field
                  class="ml-10"
                  v-model="db_search"
                  append-icon="mdi-magnify"
                  label="Name or ID"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2"
                ><v-select
                  class="mt-5"
                  clearable
                  single-line
                  small-chips
                  v-model="db_status"
                  :items="db_filters"
                  label="Status"
                  type="number"
                >
                  <v-icon slot="append">mdi-filter-menu</v-icon>
                </v-select></v-col
              >

              <v-spacer></v-spacer>
              <AddDBFile class="align-end"></AddDBFile>
            </v-toolbar>
          </template>
          <template v-slot:item.file_url="{ item }">
            <a :href="item.file_url" target="_blank">
              <span v-if="item.file_title">{{ item.file_title }}</span>
              <span v-else>{{ item.file_url }}</span>
            </a>
          </template>
          <template v-slot:item.status="{ item }">
            <v-layout justify-center>
              <v-chip label small :color="getColor(item.status)">{{
                item.status_text
              }}</v-chip>
              <!-- <v-tooltip bottom v-if="item.status == 3">
            <template v-slot:activator="{ on }">
              <v-btn fab x-small left icon v-on="on">
                <v-icon color="grey lighten-1">mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            {{ item.error_message }}
          </v-tooltip> -->
            </v-layout>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small @click="retry_db_task(item.id)" class="mr-2">
              mdi-redo
            </v-icon>
            <v-icon small @click="delete_queued_db_item(item.id)">
              mdi-delete
            </v-icon>
            <v-dialog
              ref="confirmQueue"
              v-model="dialog"
              :max-width="options.width"
              @keydown.esc="cancel"
            >
              <v-card>
                <v-toolbar dark :color="options.color" flat>
                  <v-toolbar-title class="">{{ title }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text v-show="!!message">{{ message }}</v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn color="primary darken-1" text @click.native="agree"
                    >Yes</v-btn
                  >
                  <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
            circle
            v-model="db_page"
            :length="db_pageCount"
            :total-visible="17"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
        </div>
        <v-divider class="my-5"></v-divider>
        <ListDBFiles></ListDBFiles>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import AddFile from "@/components/AddFile";
import AddDBFile from "@/components/AddDBFile";
import ListFiles from "@/components/ListFiles";
import ListDBFiles from "@/components/ListDBFiles";
import debounce from "lodash/debounce";
// import EventBus from "@/utils/eventBus";
export default {
  components: { AddFile, AddDBFile, ListFiles, ListDBFiles },
  data() {
    return {
      active_tab: 1,
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      search: "",
      db_search: "",
      status: -1,
      db_status: -1,
      table_options: {},
      db_table_options: {},
      totalQueuedFiles: 0,
      totalQueuedDBFiles: 0,
      page: 1,
      pageCount: 1,
      db_page: 1,
      db_pageCount: 1,
      selected: [],
      selected_db: [],
      filters: [
        { text: "Pending", value: 0 },
        { text: "Done", value: 1 },
        { text: "Processing", value: 2 },
        { text: "Error", value: 3 },
        { text: "Warning", value: 4 }
      ],
      db_filters: [
        { text: "Pending", value: 0 },
        { text: "Done", value: 1 },
        { text: "Processing", value: 2 },
        { text: "Error", value: 3 },
        { text: "Warning", value: 4 }
      ],
      options: {
        color: "primary",
        width: 290,
        zIndex: 200
      },
      loading: false,
      loading_db: false,
      headers: [
        {
          text: "File",
          value: "file_url",
          sortable: false
        },
        {
          text: "Account",
          value: "google_account",
          sortable: false,
          align: "center"
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "center"
          // filter: value => {
          //   if (!this.status || this.status == -1) return true;

          //   return value == parseInt(this.status);
          // }
        },
        {
          text: "Info",
          value: "status_updates",
          sortable: false,
          width: 350,
          align: "center"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ],
      headers_db: [
        {
          text: "File",
          value: "file_url",
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "center"
          // filter: value => {
          //   if (!this.status || this.status == -1) return true;

          //   return value == parseInt(this.status);
          // }
        },
        {
          text: "Info",
          value: "status_updates",
          sortable: false,
          width: 400,
          align: "center"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ]
    };
  },
  watch: {
    table_options: {
      handler() {
        this.request_queued_files();
      },
      deep: true
    },
    db_table_options: {
      handler() {
        this.request_queued_db_files();
      },
      deep: true
    },
    search: {
      handler() {
        this.debounsedSearch();
      },
      deep: true
    },
    db_search: {
      handler() {
        this.debounsedDBSearch();
      },
      deep: true
    },
    status: function() {
      this.request_queued_files();
    },
    db_status: function() {
      this.request_queued_db_files();
    }

    // queued_files: {
    //   handler() {

    //     return this.$store.getters.queued_files.filter(item => {
    //       if (!this.status || this.status == -1) return true;
    //       return item.status == this.status;
    //     });
    //   },
    //   deep: true
    // }
  },
  computed: {
    queued_files: function() {
      return this.$store.getters.queued_files.filter(item => {
        if (!this.status || this.status == -1) return true;
        return item.status == this.status;
      });
    },
    queued_db_files: function() {
      return this.$store.getters.queued_db_files.filter(item => {
        if (!this.db_status || this.db_status == -1) return true;
        return item.status == this.db_status;
      });
    }
  },
  methods: {
    retry_task(itemid) {
      // console.log("retry", itemid);
      this.$store.dispatch("retry_queue_file", itemid);
    },
    retry_db_task(itemid) {
      // console.log("retry", itemid);
      this.$store.dispatch("retry_queue_db_file", itemid);
    },
    delete_files_multi() {
      this.open("Delete", `Removing ${this.selected.length}. Are you sure?`, {
        color: "red"
      }).then(confirm => {
        if (confirm && this.selected)
          this.$store
            .dispatch("delete_queue_multi", this.selected.map(item => item.id))
            .then(() => {
              this.selected = [];
              this.request_queued_files();
            });
      });
    },
    delete_db_files_multi() {
      this.open(
        "Delete",
        `Removing ${this.selected_db.length}. Are you sure?`,
        {
          color: "red"
        }
      ).then(confirm => {
        if (confirm && this.selected_db)
          this.$store
            .dispatch(
              "delete_db_queue_multi",
              this.selected_db.map(item => item.id)
            )
            .then(() => {
              this.selected_db = [];
              this.request_queued_db_files();
            });
      });
    },
    retry_files_multi() {
      this.open("Retry", `Retrying ${this.selected.length}. Are you sure?`, {
        color: "warning"
      }).then(confirm => {
        if (confirm && this.selected)
          this.$store
            .dispatch("retry_queue_multi", this.selected.map(item => item.id))
            .then(() => {
              this.selected = [];
              this.request_queued_files();
            });
      });
    },
    retry_db_files_multi() {
      this.open("Retry", `Retrying ${this.selected_db.length}. Are you sure?`, {
        color: "warning"
      }).then(confirm => {
        if (confirm && this.selected_db)
          this.$store
            .dispatch(
              "retry_db_queue_multi",
              this.selected_db.map(item => item.id)
            )
            .then(() => {
              this.selected_db = [];
              this.request_queued_db_files();
            });
      });
    },
    delete_queued_item(itemid) {
      //   console.log(itemid);
      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm)
          this.$store
            .dispatch("delete_queue", itemid)
            .then(() => this.request_queued_files());
      });
    },
    delete_queued_db_item(itemid) {
      //   console.log(itemid);
      this.open("Delete", "Are you sure?", { color: "red" }).then(confirm => {
        if (confirm)
          this.$store
            .dispatch("delete_db_queue", itemid)
            .then(() => this.request_queued_db_files());
      });
    },
    open(title, message, options) {
      this.title = title;
      this.message = message;
      this.dialog = true;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    // filter: value => console.log(value),
    getColor(status) {
      if (status == 0 || status == 4) return "warning";
      else if (status == 1) return "success";
      else if (status == 2) return "primary";
      else if (status == 3) return "error";
      else return "gray";
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    request_queued_files() {
      this.loading = true;

      const { page, itemsPerPage } = this.table_options;

      this.$store
        .dispatch("fetch_queued_files", {
          page,
          itemsPerPage,
          status: this.status,
          search: this.search
        })
        .then(count => (this.totalQueuedFiles = count))
        .finally(() => {
          this.loading = false;
        });
    },
    request_queued_db_files() {
      this.db_loading = true;

      const { page, itemsPerPage } = this.db_table_options;

      this.$store
        .dispatch("fetch_queued_db_files", {
          page,
          itemsPerPage,
          status: this.db_status,
          search: this.db_search
        })
        .then(count => (this.totalQueuedDBFiles = count))
        .finally(() => {
          this.db_loading = false;
        });
    },
    search_queued_files() {
      if (this.search.length > 1 || this.search === "") {
        this.request_queued_files();
      }
    },
    search_queued_db_files() {
      if (this.db_search.length > 1 || this.db_search === "") {
        this.request_queued_db_files();
      }
    }
  },
  created: function() {
    this.debounsedSearch = debounce(this.search_queued_files, 500);
    this.debounsedDBSearch = debounce(this.search_queued_db_files, 500);
    this.$EventBus.$on("QUEUED_ITEM_ADD", () => {
      this.request_queued_files();
    });

    this.$EventBus.$on("QUEUED_DB_ITEM_ADD", () => {
      this.request_queued_db_files();
    });

    this.$EventBus.$on("QUEUE_ITEM_RELOAD", () => {
      this.request_queued_files();
    });
  }
};
</script>
