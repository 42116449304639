<template>
  <div>
    <v-dialog width="660" v-model="dialog" persistent
      ><template v-slot:activator="{ on }">
        <v-btn color="success" class="mb-1" dark v-on="on"
          ><v-icon left>mdi-link-plus</v-icon> Add File</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          Add New File
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="dialog"
                  @change="link_change"
                  :autofocus="true"
                  label="Dropbox link *"
                  required
                  v-model="link"
                  :error-messages="link_error"
                  hint="https://www.dropbox.com/s/n30xwtpbpv7x2i2/Akudama%20Drive%20Season%201%20Episode%2001.mp4?dl=0 <br> https://www.dropbox.com/scl/fi/f34bn7iyg999fvm66fvas/Spider-Man-Across-The-Spider-Verse-2023.mp4?rlkey=mohwaheac6ei5baj1ma0nlrcb&dl=0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="add_file">
            Add
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
              link = '';
              link_error = '';
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="660" v-model="dialog_multi" persistent
      ><template v-slot:activator="{ on }">
        <v-btn color="success" class="ml-1 mb-1" dark v-on="on"
          ><v-icon left>mdi-download-multiple</v-icon> Add Multi</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          Import Multiple IDs
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formMulti">
              <v-textarea
                persistent-hint
                v-if="dialog_multi"
                @change="links_change"
                label="Dropbox Links *"
                required
                v-model="links"
                :error-messages="links_error"
                :rules="rules"
                hint="<strong>Separated by newlines</strong> <br><br> https://www.dropbox.com/s/n30xwtpbpv7x2i2/Akudama%20Drive%20Season%201%20Episode%2001.mp4?dl=0 <br> https://www.dropbox.com/scl/fi/f34bn7iyg999fvm66fvas/Spider-Man-Across-The-Spider-Verse-2023.mp4?rlkey=mohwaheac6ei5baj1ma0nlrcb&dl=0"
              ></v-textarea>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="import_links">
            Import
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog_multi = false;
              links = '';
              links_error = '';
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";
const API_BASE = process.env.VUE_APP_API_BASE;
const GOOGLE_ACCOUNT_URL = `${API_BASE}/api/accounts`;
export default {
  data() {
    return {
      link: "",
      link_error: "",
      account_errors: [],
      dialog: false,
      dialog_multi: false,
      links: "",
      //"1YMzDLzXvC7JzlYadGLinQeQWFxktEiaH\n1OwUlGh5IPTNQD9Nbp8EdNRJZhjN1DbR5\n1ORG3yQ6U72ki--7Q7vuFo595b_T_QTNj\n1WwhMBu6-ANhP-LvtjCIqWYucFFUt46O1\n1t1GOPT3tK_gxTnjSfgr5iSZ3VYfAHfjF\n1FPpNLHWqSuwl4yokATM373AWjG5EKqaW",
      links_error: "",
      is_private: false,
      accounts: [],
      search: null,
      account: null,
      accounts_loading: false,
      rules: [v => !!v || "This field is required"],
      link_format: /(?:https?:\/\/)?www\.dropbox\.com\/s\/([\w]+)/,
      link_format2: /(?:https?:\/\/)?www\.dropbox\.com\/.*[\w]+\/\w+\/.*?\?/
    };
  },
  created() {
    this.debounsedSearch = debounce(this.search_accounts, 500);
  },
  watch: {
    search(val) {
      this.debounsedSearch(val);
    }
  },
  mounted() {},
  methods: {
    search_accounts(search) {
      // if (this.account && search == this.account.email) {
      //   return;
      // }
      if (search != null && search.length > 1) {
        this.accounts_loading = true;
        this.search_google_accounts(search)
          .then(accounts => {
            this.accounts = accounts.filter(e => {
              return (
                (e.email || "")
                  .toLowerCase()
                  .indexOf((search || "").toLowerCase()) > -1
              );
            });

            if (this.accounts) {
              this.account_errors = "";
            }
          })
          .finally(() => (this.accounts_loading = false));
      }
    },
    search_google_accounts(search) {
      return new Promise((resolve, reject) => {
        axios({
          url: GOOGLE_ACCOUNT_URL + "?search=" + search
        })
          .then(response => {
            // console.log(response);

            // commit("set_google_accounts", response.data);
            resolve(response.data);
          })
          .catch(error => reject(error));
      });
    },
    link_change() {
      this.link_error = "";
    },
    links_change() {
      this.links_error = "";
    },
    add_file() {
      this.link_error = "";
      // if (this.is_private && this.account === null) {
      //   this.account_errors = "Please select an account";
      //   return;
      // } else {
      //   this.account_errors = [];
      // }
      let file_info = {
        file_url: this.link
      };

      this.$store
        .dispatch("db_queue_file", file_info)
        .then(() => {
          this.dialog = false;
          this.link = "";
          this.link_error = "";
        })
        .catch(err => {
          if (err.response) {
            // console.log(err.response);
            if (err.response.status >= 500) {
              this.snackbar_text = err.response.statusText;
              this.snackbar = true;
              //   console.log("snackbar");
            } else if (err.response.data.error) {
              this.link_error = err.response.data.error;
            }
          } else if (err.request) {
            err.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log("Error", err.message);
          }
        });
    },
    import_links() {
      if (!this.$refs.formMulti.validate()) return;
      // if (this.is_private && this.account === null) {
      //   this.account_errors = "Please select an account";
      //   return;
      // } else {
      //   this.account_errors = [];
      // }
      this.links_error = "";
      let lines = this.links.split("\n");
      let links = [];
      lines.forEach(line => {
        if (line.startsWith("http")) {
          let m = line.match(this.link_format) || line.match(this.link_format2);
          if (m) links.push(line);
        }
      });

      if (links) {
        this.$http({
          url: `${process.env.VUE_APP_API_BASE}/api/file/db/queue_multi`,
          data: { urls: links },
          method: "POST"
        })
          .then(() => {
            this.links = "";
            this.dialog_multi = false;
            this.links_error = "";
          })
          .catch(() => {
            this.links_error = "Couldn't add the IDs";
          });
      }
    }
  }
};
</script>
