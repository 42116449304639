<template>
  <v-dialog @input="reset_step" v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="success" class="ma-2" dark v-on="on"
        ><v-icon left>mdi-account-plus</v-icon> Add Account</v-btn
      >
    </template>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" :rules="[step_1_valiate]">
          Add Account
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" :rules="[step_2_valiate]" step="2"
          >Allow Access<small v-if="step_2_valid !== true">{{
            step_2_valid
          }}</small></v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="3" color="success">Done</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <!-- <v-card-title>
              <span class="headline">Add Google Account</span>
            </v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Google Email *"
                      required
                      v-model="email"
                      :rules="emailRules"
                      :error-messages="error_messages"
                      @change="email_change"
                    ></v-text-field>
                    <!-- <small v-if="step_1_valid !== true">{{
                      step_1_valid
                    }}</small> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-switch
                      :disabled="proxy_only"
                      v-model="no_uploads"
                      label="DO NOT use this account for uploads. Eg. just for downloading"
                      color="blue darken-1"
                    ></v-switch>
                    <v-switch
                      :disabled="no_uploads"
                      v-model="proxy_only"
                      label="Proxy Only"
                      color="blue darken-1"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :loading="step_1_loading"
                color="primary"
                @click="add_google_account"
              >
                Next
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn color="blue darken-1" text @click="dialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>

          <!-- <v-btn text>Cancel</v-btn> -->
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-5" color="blue-grey lighten-5">
            <v-card-text class="font-weight-bold">
              Please click the "ALLOW" button below and complete the login
              process. Once it's done, this will complete automatically.
            </v-card-text>
          </v-card>
          <div class="text-center" v-if="step_2_loading">
            <div>Waiting</div>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <v-card-actions>
            <v-btn :href="allow_url" target="_blank" color="success">
              <v-icon left>mdi-account-check</v-icon> Allow
            </v-btn>

            <v-btn v-if="!reauth" text @click="e1 = 1">Back</v-btn>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" color="blue-grey lighten-5">
            <v-card-text>All Done!</v-card-text>
          </v-card>

          <v-card-actions>
            <!-- <v-btn color="primary" @click="add_google_account">
                Close
              </v-btn> -->
            <!-- <v-spacer></v-spacer> -->

            <v-btn color="primary darken-1" @click="dialog = false">OK</v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar :color="snackbar_color" dark v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import EventBus from "@/utils/eventBus";

export default {
  data() {
    return {
      dialog: false,
      e1: 0,
      valid: true,
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      error_messages: [],
      step_1_valid: true,
      step_2_valid: true,
      step_1_loading: false,
      step_2_loading: false,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      allow_url: "#",
      isConnected: false,
      socketMessage: "",
      uuid: "",
      no_uploads: false,
      proxy_only: false,
      reauth: false
    };
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
      // console.log("conected!");
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    }
  },
  methods: {
    reset_step() {
      this.e1 = 1;
    },
    is_connected() {
      return this.isConnected;
    },

    add_google_account() {
      //   console.log("hi");
      this.reauth = false;
      this.allow_url = "#";
      this.error_messages = [];
      this.step_1_valid = true;
      this.step_1_loading = false;
      // this.e1 = 1;
      this.step_2_valid = true;
      this.step_2_loading = false;

      for (let index = 0; index < this.emailRules.length; index++) {
        const validate = this.emailRules[index];
        // console.log(validate);
        let error = validate(this.email);
        if (typeof error === "string") {
          // console.log(error);
          this.error_messages.push(error);
          return;
        }
      }

      if (this.error_messages.length > 0) {
        // console.log("errr");
        return;
      }

      this.step_1_loading = true;

      this.$http({
        url: `${process.env.VUE_APP_API_BASE}/api/account/add`,
        data: {
          email: this.email,
          no_uploads: this.no_uploads,
          proxy_only: this.proxy_only
        },
        method: "POST"
      })
        .then(response => {
          // console.log(response);
          if (response.data.added == false) {
            if (response.data.error) {
              this.step_1_valid = response.data.error;
            } else {
              this.step_1_valid = "Error Adding";
            }
            this.error_messages = [this.step_1_valid];
            // return;
          }
          this.allow_url = response.data.redirect;
          this.uuid = response.data.uuid;

          this.step_2_loading = true;
          this.e1 = 2;
          this.$store.dispatch("fetch_google_accounts");
        })
        .catch(err => {
          if (err.response) {
            // console.log(err.response);
            if (err.response.status >= 500) {
              this.snackbar_text = err.response.statusText;
              this.snackbar = true;
              //   console.log("snackbar");
            } else if (err.response.data.error) {
              this.step_1_valid = err.response.data.error;
              this.error_messages = [this.step_1_valid];
            }
          } else if (err.request) {
            err.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            "Error", err.message;
          }
          this.allow_url = "#";
        })
        .finally(() => (this.step_1_loading = false));
      //   console.log(this.$http);
      //   this.e1 = 2;
    },
    error() {
      //   console.log(input);
      return this.valid;
    },
    email_change: function() {
      this.step_1_valid = true;
      this.error_messages = [];
      // console.log("changed");
    },
    step_1_valiate() {
      return this.step_1_valid;
    },
    step_2_valiate() {
      return this.step_2_valid;
    }
  },
  // computed: {
  //   get_uuid: () => uuid
  // },
  mounted() {
    EventBus.$on("GOOGLE_AUTH_SUCCESS", () => {
      this.step_2_loading = false;
      this.e1 = 3;
      this.$store.dispatch("fetch_google_accounts");
      this.reauth = false;
    });
    EventBus.$on("GOOGLE_AUTH_FAIL", ctx => {
      this.step_2_loading = false;
      // this.e1 = 3;
      this.step_2_valid = "Something went wrong";
      ctx;
      this.reauth = false;
    });
    EventBus.$on("GOOGLE_AC_AUTH", allow_url => {
      this.reauth = true;
      this.error_messages = [];
      this.step_1_valid = true;
      this.allow_url = allow_url;
      this.step_2_loading = true;
      this.e1 = 2;
      this.dialog = true;
      // console.log(ctx);
    });
  }
};
</script>
