import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=76469a08&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=E%3A%5CHimal%5Cprojects%5Crusshimi%5CGStream%5Cwebapp%5Csrc%5Cviews%5CMain.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./Main.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)
import block2 from "./Main.vue?vue&type=custom&index=2&blockType=i18n"
if (typeof block2 === 'function') block2(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VSnackbar,VTab,VTabItem,VTabs})
