import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import EventBus from "@/utils/eventBus";
import Observer from "vue-native-websocket";

// import router from "./router";
Vue.use(Vuex);

const API_BASE = process.env.VUE_APP_API_BASE;

const LOGIN_URL = `${API_BASE}/api/login`;
const TOKEN_REFRESH_URL = `${API_BASE}/api/token/refresh`;
const FILE_QUEUE_URL = `${API_BASE}/api/file/queue`;
const FILE_DB_QUEUE_URL = `${API_BASE}/api/file/db/queue`;
const FILE_QUEUE_MULTI_DELETE_URL = `${API_BASE}/api/file/queue/delete`;
const FILE_DB_QUEUE_MULTI_DELETE_URL = `${API_BASE}/api/file/db/queue/delete`;
const MEDIA_FILE_MULTI_DELETE_URL = `${API_BASE}/api/file/media/delete`;
const MEDIA_DB_FILE_MULTI_DELETE_URL = `${API_BASE}/api/file/db/media/delete`;
const FILE_QUEUE_MULTI_RETRY_URL = `${API_BASE}/api/file/queue/retry`;
const FILE_DB_QUEUE_MULTI_RETRY_URL = `${API_BASE}/api/file/db/queue/retry`;
const MEDIA_FILE_URL = `${API_BASE}/api/file/media`;
const MEDIA_DB_FILE_URL = `${API_BASE}/api/file/db/media`;
const APP_ADD_URL = `${API_BASE}/api/app/add`;
const GOOGLE_APP_URL = `${API_BASE}/api/apps`;
const GOOGLE_ACCOUNT_URL = `${API_BASE}/api/accounts`;
const GOOGLE_DRIVE_URL = `${API_BASE}/api/account/drives`;
const DELETE_SHARED_DRIVE_URL = `${API_BASE}/api/account/drive`;
const ADD_SHARED_DRIVE_URL = `${API_BASE}/api/account/drives/add`;
const ADD_SHARED_DRIVE_MEMBERS_URL = `${API_BASE}/api/account/drive/members`;
const DELETE_GOOGLE_ACCOUNT_URL = `${API_BASE}/api/account`;
const QUEUED_FILES_URL = `${API_BASE}/api/file/queue`;
const QUEUED_DB_FILES_URL = `${API_BASE}/api/file/db/queue`;
const ADS_URL = `${API_BASE}/api/ads`;
const TEST_FETCH = `${API_BASE}/api/private`;

// const QUEUE_DELET_URL = `${API_BASE}/api/file/queue`;
const SOCKET_QUEUED_FILE_ADD = "QUEUED_FILE_ADD";
const SOCKET_QUEUED_DB_FILE_ADD = "QUEUED_DB_FILE_ADD";
const SOCKET_QUEUED_FILE_UPDATE = "QUEUED_FILE_UPDATE";
const SOCKET_QUEUED_DB_FILE_UPDATE = "QUEUED_DB_FILE_UPDATE";
const SOCKET_MEDIA_FILE_ADD = "MEDIA_FILE_ADD";
const SOCKET_MEDIA_FILE_DB_ADD = "MEDIA_FILE_DB_ADD";
const SOCKET_APP_ADD = "APP_ADD";

const token = localStorage.getItem("token") || null;
const rftoken = localStorage.getItem("rftoken") || null;

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {},
    token: token,
    rftoken: rftoken,
    google_apps: [],
    google_accounts: [],
    queued_files: [],
    media_files: [],
    queued_db_files: [],
    media_db_files: [],
    shared_drives: [],
    refreshing_token_handler: null,
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
      not_auth: false
    },
    ads: {
      "0": { active: false, content: "" },
      "1": { active: false, content: "" },
      "2": { active: false, content: "" }
    }
  },
  mutations: {
    SOCKET_ONERROR() {},
    SOCKET_ONOPEN(state, event) {
      // console.log("onopen");
      Observer;

      // const WS_MESSAGES = "ws://localhost:8000/ws";

      // Vue.use(VueNativeSock, WS_MESSAGES, {
      //   connectManually: false,
      //   store: store,
      //   format: "json",
      //   reconnection: true, // (Boolean) whether to reconnect automatically (false)
      //   reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
      //   reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
      // });
      // console.log(event);
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      // console.log(Vue.prototype.$socket);
      if (state.socket.not_auth) return;
      if (this.getters.isLoggedIn) {
        // console.log("logged in");
        Vue.prototype.$socket.sendObj({ type: "auth", token: state.token });
      }
      // console.log("onopen end");
    },
    SOCKET_ONCLOSE(state, event) {
      // console.log("close");
      // console.info(state, event);
      state.socket.isConnected = false;
      // console.log(Vue.prototype.$socket);
      if (event.code == 4001) state.socket.not_auth = true;
      // console.log("close end", event.code);
      // Vue.prototype.$socket.disconnect();
    },

    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
      // console.log(message);
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state) {
      // console.log("reconnect");
      // console.info(state, count);
      // console.log(this.getters.isLoggedIn);
      if (this.getters.isLoggedIn) {
        // console.log("logged in");
        Vue.prototype.$socket.sendObj({ type: "auth", token: state.token });
      }
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    [SOCKET_APP_ADD]() {
      EventBus.$emit("GOOGLE_APP_ADD");
    },
    [SOCKET_QUEUED_FILE_UPDATE](state, message) {
      const item = state.queued_files.find(item => {
        return item.id === message.item.id;
      });

      if (item === undefined) {
        return;
      } else {
        let file_idx = state.queued_files.indexOf(item);
        state.queued_files[file_idx] = Object.assign(item, message.item);
      }
    },
    [SOCKET_QUEUED_DB_FILE_UPDATE](state, message) {
      const item = state.queued_db_files.find(item => {
        return item.id === message.item.id;
      });

      if (item === undefined) {
        return;
      } else {
        let file_idx = state.queued_db_files.indexOf(item);
        state.queued_db_files[file_idx] = Object.assign(item, message.item);
      }
    },
    [SOCKET_MEDIA_FILE_ADD]() {
      // console.log("SOCKET_MEDIA_FILE_ADD", message);
      // state.media_files.push(message.item);
      EventBus.$emit("MEDIA_FILE_ADD");
      // const item = state.media_files.find(item => item.id === message.item.id);
      // if (item === undefined) return;
      // Object.assign(item, message.item);
    },
    [SOCKET_MEDIA_FILE_DB_ADD]() {
      // console.log("SOCKET_MEDIA_FILE_ADD", message);
      // state.media_files.push(message.item);
      EventBus.$emit("MEDIA_FILE_DB_ADD");
      // const item = state.media_files.find(item => item.id === message.item.id);
      // if (item === undefined) return;
      // Object.assign(item, message.item);
    },
    [SOCKET_QUEUED_FILE_ADD]() {
      //state.queued_files.push(Object.assign({}, message.item));
      EventBus.$emit("QUEUED_ITEM_ADD");

      // const item = state.media_files.find(item => item.id === message.item.id);
      // if (item === undefined) return;
      // Object.assign(item, message.item);
    },
    [SOCKET_QUEUED_DB_FILE_ADD]() {
      EventBus.$emit("QUEUED_DB_ITEM_ADD");
    },
    set_rftoken_request_handler(state, handler) {
      state.refreshing_token_handler = handler;
    },
    set_google_accounts(state, google_accounts) {
      state.google_accounts = google_accounts;
    },
    set_google_apps(state, google_apps) {
      state.google_apps = google_apps;
    },
    set_shared_drives(state, shared_drives) {
      state.shared_drives = shared_drives;
    },
    add_shared_drive(state, shared_drive) {
      state.shared_drives.push(shared_drive);
    },
    add_shared_drive_members(state, data) {
      let drive_idx = state.shared_drives.indexOf(data.drive);
      state.shared_drives[drive_idx].members = data.accounts;
    },
    login(state, auth) {
      state.token = auth.token;
      state.rftoken = auth.rftoken;
      state.user = auth.user;
      state.socket.not_auth = false;
    },
    logout(state) {
      state.token = null;
      state.rftoken = null;
      state.user = {};
      state.socket.not_auth = true;
    },
    set_token(state, token) {
      state.token = token;
    },
    set_queued_files(state, files) {
      state.queued_files = files;
    },
    set_queued_db_files(state, files) {
      state.queued_db_files = files;
    },
    update_queued_file(state, file_id) {
      let file_idx = state.queued_files.map(item => item.id).indexOf(file_id);
      // console.log(file_idx);
      state.queued_files[file_idx].status = 0;
      state.queued_files[file_idx].status_text = "Pending";
      state.queued_files[file_idx].status_updates = "Scheduled";
    },
    update_queued_db_file(state, file_id) {
      let file_idx = state.queued_db_files
        .map(item => item.id)
        .indexOf(file_id);
      // console.log(file_idx);
      state.queued_db_files[file_idx].status = 0;
      state.queued_db_files[file_idx].status_text = "Pending";
      state.queued_db_files[file_idx].status_updates = "Scheduled";
    },
    remove_queued_file(state, file_id) {
      let file_idx = state.queued_files.map(item => item.id).indexOf(file_id);
      // console.log(file_idx);
      state.queued_files.splice(file_idx, 1);
    },
    remove_queued_db_file(state, file_id) {
      let file_idx = state.queued_db_files
        .map(item => item.id)
        .indexOf(file_id);
      // console.log(file_idx);
      state.queued_db_files.splice(file_idx, 1);
    },
    set_media_files(state, files) {
      state.media_files = files;
    },
    set_media_db_files(state, files) {
      state.media_db_files = files;
    },
    remove_media_file(state, file_id) {
      let file_idx = state.media_files.map(item => item.id).indexOf(file_id);
      // console.log(file_idx);
      state.media_files.splice(file_idx, 1);
    },
    remove_media_db_file(state, file_id) {
      let file_idx = state.media_db_files.map(item => item.id).indexOf(file_id);
      // console.log(file_idx);
      state.media_db_files.splice(file_idx, 1);
    },
    edit_app(state, app) {
      const app_item = state.google_apps.find(item => item.id === app.app_id);
      if (app_item === undefined) return;
      Object.assign(app_item, app);
    },
    remove_app(state, app_id) {
      let app_idx = state.google_apps.map(item => item.id).indexOf(app_id);
      // console.log(app_idx);
      state.google_apps.splice(app_idx, 1);
    },
    delete_google_account(state, file_id) {
      let file_idx = state.google_accounts
        .map(item => item.id)
        .indexOf(file_id);
      // console.log(file_idx);
      state.google_accounts.splice(file_idx, 1);
    },
    delete_shared_drive(state, drive_id) {
      let drive_idx = state.shared_drives
        .map(item => item.id)
        .indexOf(drive_id);
      // console.log(drive_idx);
      state.shared_drives.splice(drive_idx, 1);
    },
    set_ads(state, ads) {
      state.ads = Object.assign(state.ads, ads);
    }
  },
  actions: {
    complete_google_auth() {
      EventBus.$emit("GOOGLE_AUTH_SUCCESS");
    },
    fail_google_auth() {
      EventBus.$emit("GOOGLE_AUTH_FAIL");
    },

    delete_media({ commit }, file_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: MEDIA_FILE_URL + "/" + file_id,
          method: "DELETE"
        })
          .then(resp => {
            commit("remove_media_file", file_id);
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_db_media({ commit }, file_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: MEDIA_DB_FILE_URL + "/" + file_id,
          method: "DELETE"
        })
          .then(resp => {
            commit("remove_media_db_file", file_id);
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_queue({ commit }, file_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: FILE_QUEUE_URL + "/" + file_id,
          method: "DELETE"
        })
          .then(resp => {
            commit("remove_queued_file", file_id);
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_db_queue({ commit }, file_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: FILE_DB_QUEUE_URL + "/" + file_id,
          method: "DELETE"
        })
          .then(resp => {
            commit("remove_queued_db_file", file_id);
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_media_multi({ commit }, file_ids) {
      return new Promise((resolve, reject) => {
        axios({
          url: MEDIA_FILE_MULTI_DELETE_URL,
          method: "POST",
          data: { file_ids }
        })
          .then(resp => {
            commit;
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_db_media_multi({ commit }, file_ids) {
      return new Promise((resolve, reject) => {
        axios({
          url: MEDIA_DB_FILE_MULTI_DELETE_URL,
          method: "POST",
          data: { file_ids }
        })
          .then(resp => {
            commit;
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_queue_multi({ commit }, file_ids) {
      return new Promise((resolve, reject) => {
        axios({
          url: FILE_QUEUE_MULTI_DELETE_URL,
          method: "POST",
          data: { file_ids }
        })
          .then(resp => {
            commit;
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_db_queue_multi({ commit }, file_ids) {
      return new Promise((resolve, reject) => {
        axios({
          url: FILE_DB_QUEUE_MULTI_DELETE_URL,
          method: "POST",
          data: { file_ids }
        })
          .then(resp => {
            commit;
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    retry_queue_multi({ commit }, file_ids) {
      return new Promise((resolve, reject) => {
        axios({
          url: FILE_QUEUE_MULTI_RETRY_URL,
          method: "POST",
          data: { file_ids }
        })
          .then(resp => {
            commit;
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    retry_db_queue_multi({ commit }, file_ids) {
      return new Promise((resolve, reject) => {
        axios({
          url: FILE_DB_QUEUE_MULTI_RETRY_URL,
          method: "POST",
          data: { file_ids }
        })
          .then(resp => {
            commit;
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    delete_app({ commit }, app_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: GOOGLE_APP_URL + "/" + app_id,
          method: "DELETE"
        })
          .then(resp => {
            commit("remove_app", app_id);
            // dispatch("fetch_queued_files");
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    retry_queue_file({ commit, dispatch }, file_id) {
      commit("update_queued_file", file_id);
      return new Promise((resolve, reject) => {
        axios({ url: FILE_QUEUE_URL + "/retry/" + file_id, method: "POST" })
          .then(resp => {
            // dispatch("fetch_queued_files");

            // dispatch;
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
        commit;
        dispatch;
      });
    },
    retry_queue_db_file({ commit, dispatch }, file_id) {
      commit("update_queued_db_file", file_id);
      return new Promise((resolve, reject) => {
        axios({ url: FILE_DB_QUEUE_URL + "/retry/" + file_id, method: "POST" })
          .then(resp => {
            // dispatch("fetch_queued_files");

            // dispatch;
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
        commit;
        dispatch;
      });
    },
    queue_file({ commit, dispatch }, file_info) {
      return new Promise((resolve, reject) => {
        axios({ url: FILE_QUEUE_URL, data: file_info, method: "POST" })
          .then(resp => {
            dispatch("fetch_queued_files");
            // commit('add_queued_file', file_info)
            // dispatch;
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
        commit;
      });
    },
    db_queue_file({ commit, dispatch }, file_info) {
      return new Promise((resolve, reject) => {
        axios({ url: FILE_DB_QUEUE_URL, data: file_info, method: "POST" })
          .then(resp => {
            dispatch("fetch_queued_db_files");
            // commit('add_queued_file', file_info)
            // dispatch;
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
        commit;
      });
    },
    add_app({ commit, dispatch }, app_info) {
      return new Promise((resolve, reject) => {
        axios({ url: APP_ADD_URL, data: app_info, method: "POST" })
          .then(resp => {
            dispatch("fetch_google_apps");
            // commit('add_queued_file', file_info)
            // dispatch;
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
        commit;
      });
    },
    edit_app({ commit }, app_info) {
      return new Promise((resolve, reject) => {
        axios({
          url: GOOGLE_APP_URL + "/edit/" + app_info.app_id,
          data: app_info,
          method: "POST"
        })
          .then(resp => {
            // dispatch("fetch_queued_files");
            commit("edit_app", app_info);
            // dispatch;
            resolve(resp);
          })
          .catch(err => {
            // console.log(resp);
            reject(err);
          });
      });
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) =>
        axios({ url: LOGIN_URL, data: user, method: "POST" })
          .then(resp => {
            // console.log("then");
            // console.log(resp);
            const token = resp.data.access;
            const rftoken = resp.data.refresh;
            const user = resp.data.user;
            localStorage.setItem("token", token);
            localStorage.setItem("rftoken", rftoken);
            localStorage.setItem("user", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            commit("login", { token, rftoken, user });
            Vue.prototype.$socket.sendObj({ type: "auth", token: token });
            resolve(resp);
          })
          .catch(err => {
            // if (err.response.status == 401) {
            //   // console.log("relogin");
            // }

            localStorage.removeItem("token");
            reject(err);
          })
      );
    },

    refresh_token({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios({
          url: TOKEN_REFRESH_URL,
          data: { refresh: state.rftoken },
          method: "POST"
        })
          //commit("set_rftoken_request_handler", request);

          .then(resp => {
            const token = resp.data.access;

            commit("set_token", token);
            localStorage.setItem("token", token);
            // localStorage.setItem("rftoken", rftoken);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            // console.log("socket login");
            Vue.prototype.$socket.sendObj({ type: "reauth", token: token });
            // EventBus.$emit("USER_LOGIN");
            resolve(token);
          })
          .catch(err => {
            // console.log("catch");
            // localStorage.removeItem("token");
            // localStorage.removeItem("rftoken");
            // commit("logout");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      commit("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("rftoken");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      Vue.prototype.$socket.sendObj({ type: "logout" });
      EventBus.$emit("USER_LOGOUT");
    },

    fetch_google_apps({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: GOOGLE_APP_URL
        })
          .then(response => {
            // console.log(response);

            commit("set_google_apps", response.data);
            resolve();
          })
          .catch(error => reject(error));
      });
    },

    fetch_shared_drives({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: GOOGLE_DRIVE_URL
        })
          .then(response => {
            // console.log(response);

            commit("set_shared_drives", response.data);
            resolve();
          })
          .catch(error => reject(error));
      });
    },

    add_shared_drive({ commit }, drive_info) {
      return new Promise((resolve, reject) => {
        axios({
          url: ADD_SHARED_DRIVE_URL,
          data: drive_info,
          method: "POST"
        })
          .then(response => {
            // console.log(response);
            commit("add_shared_drive", response.data["drive"]);
            resolve();
          })
          .catch(error => reject(error));
      });
    },

    add_shared_drive_members({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: ADD_SHARED_DRIVE_MEMBERS_URL,
          data: {
            drive_id: data.drive.id,
            members: data.accounts.map(({ id }) => id)
          },
          method: "POST"
        })
          .then(() => {
            commit("add_shared_drive_members", data);
            resolve();
          })
          .catch(error => reject(error));
      });
    },

    delete_shared_drive({ commit }, drive_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: DELETE_SHARED_DRIVE_URL + "/" + drive_id,
          data: drive_id,
          method: "DELETE"
        })
          .then(() => {
            commit("delete_shared_drive", drive_id);
            resolve();
          })
          .catch(error => reject(error));
      });
    },

    fetch_google_accounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: GOOGLE_ACCOUNT_URL
        })
          .then(response => {
            // console.log(response.data);

            commit("set_google_accounts", response.data);
            resolve();
          })
          .catch(error => reject(error));
      });
    },

    delete_google_account({ commit }, account_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: DELETE_GOOGLE_ACCOUNT_URL + "/" + account_id,
          method: "DELETE"
        })
          .then(() => {
            // console.log(response);

            commit("delete_google_account", account_id);
            resolve();
          })
          .catch(error => reject(error));
      });
    },
    fetch_queued_files({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: QUEUED_FILES_URL,
          params: data
        })
          .then(response => {
            // console.log(response);

            commit("set_queued_files", response.data.items);
            resolve(response.data.count);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetch_queued_db_files({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: QUEUED_DB_FILES_URL,
          params: data
        })
          .then(response => {
            // console.log(response);

            commit("set_queued_db_files", response.data.items);
            resolve(response.data.count);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetch_media_files({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: MEDIA_FILE_URL,
          params: data
        })
          .then(response => {
            // console.log(response);

            commit("set_media_files", response.data.items);
            resolve(response.data.count);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetch_media_db_files({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: MEDIA_DB_FILE_URL,
          params: data
        })
          .then(response => {
            // console.log(response);

            commit("set_media_db_files", response.data.items);
            resolve(response.data.count);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetch_ads({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: ADS_URL,
          method: "GET"
        })
          .then(resp => {
            commit("set_ads", resp.data);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    save_ad({ state }, idx) {
      let data = state.ads[idx];
      data["location"] = idx;
      return new Promise((resolve, reject) => {
        axios({
          url: ADS_URL,
          method: "POST",
          data
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetch() {
      return new Promise((resolve, reject) => {
        axios({
          url: TEST_FETCH,
          method: "GET"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    rftoken: state => state.rftoken,
    user: state => state.user,
    google_apps: state => state.google_apps,
    google_accounts: state => state.google_accounts,
    shared_drives: state => state.shared_drives,
    queued_files: state => state.queued_files,
    queued_db_files: state => state.queued_db_files,
    media_files: state => state.media_files,
    media_db_files: state => state.media_db_files,
    rft_handler: state => state.refreshing_token_handler,
    ads: state => state.ads
  }
});
