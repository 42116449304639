import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Main from "./views/Main.vue";
import OAuth from "./views/OAuth.vue";
import Login from "@/components/Login.vue";
import store from "@/store.js";
import EventBus from "@/utils/eventBus";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/app",
      name: "app",
      component: Main,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/cb",
      name: "cb",
      component: OAuth
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  // console.log(to, from, next);
  if (to.name === "login" && store.getters.isLoggedIn) {
    next("/");
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    EventBus.$emit("SHOW_INFO", "Please sign in to continue");
    next("/login");
  } else {
    next();
  }
});

export default router;
