<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :mini-variant="$vuetify.breakpoint.xsOnly"
      mobile-break-point="960px"
      color="primary"
      class="indigo darken-1"
      dark
    >
      <v-list dense>
        <v-list-item to="/app">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>App</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="clicked">
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="clicked" v-if="!isLoggedIn" disabled>
          <v-list-item-action>
            <v-icon>mdi-account-star</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign up</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!isLoggedIn" to="/login">
          <v-list-item-action>
            <v-icon>mdi-login</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign in</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isLoggedIn" @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <!-- <v-toolbar-items>
        <v-btn active-class="" class="font-weight-black" to="/" text
          >{{ appTitle }}
        </v-btn>
      </v-toolbar-items> -->

      <v-toolbar-title class="font-weight-black">{{
        appTitle
      }}</v-toolbar-title>

      <!-- <router-link to="/" class="toolbar-title">
        {{ appTitle }}
      </router-link> -->

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-tooltip
          v-if="!isLoggedIn"
          bottom
          :disabled="!$vuetify.breakpoint.xs"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              disabled
              @click="clicked"
              v-on="on"
              text
              :small="$vuetify.breakpoint.xs"
            >
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-account-star</v-icon>
              <span v-show="!$vuetify.breakpoint.xs">Sign up</span>
            </v-btn>
          </template>
          <span>Sign Up</span>
        </v-tooltip>
        <v-tooltip
          v-if="!isLoggedIn"
          bottom
          :disabled="!$vuetify.breakpoint.xs"
        >
          <template v-slot:activator="{ on }">
            <v-btn to="login" v-on="on" text :small="$vuetify.breakpoint.xs">
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-login</v-icon>
              <span v-show="!$vuetify.breakpoint.xs">Sign in</span>
            </v-btn>
          </template>
          <span>Sign In</span>
        </v-tooltip>
        <!-- <v-tooltip v-if="isLoggedIn" bottom>
          <template v-slot:activator="{ on }"> -->

        <!-- </template>
          <span>Account</span>
        </v-tooltip> -->
        <!-- <v-tooltip :disabled="!$vuetify.breakpoint.xs" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-show="isLoggedIn"
              @click="logout"
              v-on="on"
              text
              :small="$vuetify.breakpoint.xs"
            >
              <v-icon :left="!$vuetify.breakpoint.xs">mdi-logout</v-icon>
              <span v-show="!$vuetify.breakpoint.xs">Sign out</span>
            </v-btn>
          </template>
          <span>Sign Out</span>
        </v-tooltip> -->
      </v-toolbar-items>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        v-if="isLoggedIn"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <!-- <img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                    alt="John"
                  /> -->
                <!-- <v-avatar color="red">
                  <span class="white--text headline">JD</span>
                </v-avatar> -->
                <vue-letter-avatar
                  :name="user.username"
                  size="40"
                  :rounded="true"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.username }}</v-list-item-title>
                <!-- <v-list-item-subtitle
                    >Organic Software Specialist</v-list-item-subtitle
                  > -->
              </v-list-item-content>

              <v-list-item-action>
                <!-- <v-btn :class="user.pro ? 'green--text' : ''" icon> -->
                <v-icon :class="user.pro ? 'green--text' : ''"
                  >mdi-professional-hexagon</v-icon
                >
                <!-- </v-btn> -->
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ user.data_quota | prettyBytes }}</strong>
                  Remaining</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="notifications" color="warning"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Enable Notifications</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-btn class="" block @click.stop="logout">
                <v-icon left>mdi-logout</v-icon> Sign out
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-content>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex>
            <router-view></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>

    <v-footer app class="subtitle-2">
      <v-flex text-center xs12> &copy; {{ new Date().getFullYear() }} </v-flex>
    </v-footer>
    <!-- <v-bottom-sheet v-model="sheet" persistent>
      <template v-slot:activator="{ on }">
        <v-btn color="green" dark v-on="on">
          Open Persistent
        </v-btn>
      </template>
      <v-sheet class="text-center" height="150px">
        <v-btn class="mt-6" color="error" @click="sheet = !sheet">got it</v-btn>
        <div class="py-1">This is still in beta stage.</div>
      </v-sheet>
    </v-bottom-sheet> -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      :color="snackbar_color"
      :top="false"
      :right="true"
      dense
    >
      {{ snackbar_text }}

      <v-btn text @click="snackbar = false">
        dismiss
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import EventBus from "@/utils/eventBus";
import axios from "axios";
// import router from "./router";

// token
let authTokenRequest;

function getAuthToken(that) {
  if (!authTokenRequest) {
    authTokenRequest = makeActualAuthenticationRequest(that);
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

function makeActualAuthenticationRequest(that) {
  return that.$store.dispatch("refresh_token");
}

export default {
  props: {
    source: String,
    hints: [],
    message: String,
    fav: Boolean
  },
  data() {
    return {
      snackbar_timeout: 2500,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      sheet: true,
      appTitle: "GDStreamer",
      drawer: null,
      menu: false,
      notifications: true
      // source: "http://example.com"
    };
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    user: function() {
      return this.$store.getters.user;
    }
  },

  methods: {
    clicked() {
      // console.log(this.$store.getters.isLoggedIn);
    },
    logout() {
      this.$store.dispatch("logout");
      this.menu = false;
    },
    show_snackbar(error) {
      this.snackbar_color = error.color;
      this.snackbar_text = error.message;
      this.snackbar = true;
    }
  },
  mounted() {
    // this.$connect("ws://localhost:8000/ws", {
    //   format: "json"
    // });
    EventBus.$on("USER_LOGIN", () => {
      this.loading = false;
      this.$router.push("app").catch(() => {
        // console.log(this.$router);
        // console.log(err);
      });
    });
    EventBus.$on("USER_LOGOUT", () => {
      this.$router.push("login").catch(() => {
        // console.log(this.$router);
        // console.log(err);
      });
    });
    this.sheet = false;
  },

  created() {
    // console.log(this.$socket);
    // console.log(this.$options.sockets);
    // this.$connect(WS_MESSAGES, {
    //   format: "json"
    // });
    // this.$store.dispatch("init");

    EventBus.$on("SNACKBAR", this.show_snackbar);

    axios.interceptors.response.use(
      response => {
        // Return a successful response back to the calling service
        return response;
      },
      error => {
        //const originalRequest = error.config;

        // Return any error which is not due to authentication back to the calling service
        if (
          (error.response && error.response.status !== 401) ||
          !error.response
        ) {
          //console.log(error.response);
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        //console.log(error.config);
        //console.log(error.response);
        // console.log(error.config.url == `${process.env.VUE_APP_API_BASE}/api/login`);
        if (error.config.url == `${process.env.VUE_APP_API_BASE}/api/login`) {
          // router.push({ name: "login" });

          this.$store.dispatch("logout").catch();
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        // Logout user if token refresh didn't work or user is disabled
        if (
          error.config.url ==
            `${process.env.VUE_APP_API_BASE}/api/token/refresh` ||
          error.response.message == "Account is disabled."
        ) {
          // TokenStorage.clear();
          EventBus.$emit("SHOW_INFO", "Please sign in to continue");
          this.$store.dispatch("logout").catch();

          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        // console.log(error.config.url);

        // Try request again with new token
        if (!error.config.__isRetryRequest) {
          //console.log(error.config);

          return getAuthToken(this)
            .then(token => {
              //console.log(config);
              error.config.headers["Authorization"] = `Bearer ${token}`;
              //console.log(token);
              error.config.__isRetryRequest = true;
              //error.config.headers["Authorization"] = "Bearer " + token;
              //return axios(error.config);
              return new Promise((resolve, reject) => {
                axios
                  .request(error.config)
                  .then(response => {
                    resolve(response);
                  })
                  .catch(error => {
                    reject(error);
                  });
              });
            })
            .catch(error => {
              return new Promise((resolve, reject) => {
                reject(error);
              });
            });
        }
      }
    );
  },
  filters: {
    truncate: function(text, length, suffix) {
      return text.substring(0, length) + suffix;
    }
  }
};
</script>
<style>
/* .toolbar-title {
  color: inherit;
  text-decoration: inherit;
} */
</style>
