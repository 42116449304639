<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="success" class="ma-2" dark v-on="on"
        ><v-icon left>mdi-upload-multiple</v-icon> Import Accounts</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Import Google Accounts</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form">
            <v-row>
              <v-col cols="12">
                <v-textarea
                  @change="errors = []"
                  :error-messages="errors"
                  v-model="emails"
                  :rules="[required]"
                  clearable
                  label="Google Emails *"
                  required
                ></v-textarea>
                <!-- <small v-if="step_1_valid !== true">{{
                      step_1_valid
                    }}</small> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><v-switch
                  :disabled="proxy_only"
                  v-model="no_uploads"
                  label="DO NOT use these accounts for uploads. Eg. just for downloading"
                  color="blue darken-1"
                ></v-switch>
                <v-switch
                  :disabled="no_uploads"
                  v-model="proxy_only"
                  label="Proxy Only"
                  color="blue darken-1"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="loading"
          color="primary"
          @click="import_google_accounts"
          :disabled="!form"
        >
          Import
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          color="blue darken-1"
          text
          @click="
            dialog = false;
            errors = '';
          "
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar :color="snackbar_color" dark v-model="snackbar" :timeout="4000">
      {{ snackbar_text }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// import EventBus from "@/utils/eventBus";

export default {
  data() {
    return {
      form: false,
      dialog: false,
      e1: 0,
      valid: true,
      emails: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      errors: [],
      //   step_1_valid: true,
      step_2_valid: true,
      loading: false,
      step_2_loading: false,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      allow_url: "#",
      isConnected: false,
      socketMessage: "",
      uuid: "",
      no_uploads: false,
      proxy_only: false,
      reauth: false
    };
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
      // console.log("conected!");
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    }
  },
  methods: {
    import_google_accounts() {
      this.loading = true;
      this.snackbar = false;
      this.snackbar_text = "";
      this.snackbar_color = "";

      this.$http({
        url: `${process.env.VUE_APP_API_BASE}/api/account/import`,
        method: "POST",
        data: {
          emails: this.emails.split("\n"),
          no_uploads: this.no_uploads,
          proxy_only: this.proxy_only
        }
      })
        .then(resp => {
          // console.log(resp);
          if (!resp.data.imported) {
            this.errors.push(resp.error);
          } else {
            this.snackbar = true;
            this.snackbar_text =
              "Successfully imported. please authorized them.";
            this.snackbar_color = "success";
            this.$store.dispatch("fetch_google_accounts");
          }
        })
        .catch(err => {
          if (err.response) {
            // console.log(err.response);
            this.errors.push(err.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    required: v => !!v || "This field is required",
    reset_step() {
      this.e1 = 1;
    },
    is_connected() {
      return this.isConnected;
    },

    error() {
      //   console.log(input);
      return this.valid;
    },
    email_change: function() {
      this.step_1_valid = true;
      this.error_messages = [];
      // console.log("changed");
    },
    step_1_valiate() {
      return this.step_1_valid;
    },
    step_2_valiate() {
      return this.step_2_valid;
    }
  }
  // computed: {
  //   get_uuid: () => uuid
  // },
  //   mounted() {
  //     EventBus.$on("GOOGLE_AUTH_SUCCESS", () => {
  //       this.step_2_loading = false;
  //       this.e1 = 3;
  //       this.$store.dispatch("fetch_google_accounts");
  //       this.reauth = false;
  //     });
  //     EventBus.$on("GOOGLE_AUTH_FAIL", ctx => {
  //       this.step_2_loading = false;
  //       // this.e1 = 3;
  //       this.step_2_valid = "Something went wrong";
  //       ctx;
  //       this.reauth = false;
  //     });
  //     EventBus.$on("GOOGLE_AC_AUTH", allow_url => {
  //       this.reauth = true;
  //       this.error_messages = [];
  //       this.step_1_valid = true;
  //       this.allow_url = allow_url;
  //       this.step_2_loading = true;
  //       this.e1 = 2;
  //       this.dialog = true;
  //       // console.log(ctx);
  //     });
  //   }
};
</script>
