<template>
  <div>
    <v-alert v-if="info" type="warning" dense="">
      {{ info }}
    </v-alert>
    <v-alert v-if="error" type="error" dense="">
      {{ error }}
    </v-alert>
    <v-card :loading="loading">
      <!-- <v-expand-transition> -->

      <!-- </v-expand-transition> -->
      <v-card-title>
        <v-toolbar dark flat color="primary">
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light">Sign In</v-toolbar-title>
        </v-toolbar></v-card-title
      >

      <v-card-text>
        <v-form ref="form" :lazy-validation="true" v-model="valid">
          <v-text-field
            prepend-icon="mdi-account"
            v-model="username"
            :counter="10"
            :rules="nameRules"
            label="Username or Email address"
            required
            :error="errors.username.error"
            :error-count="
              errors.username.error ? errors.username.messages.length : 1
            "
            :error-messages="
              errors.username.error ? errors.username.messages : []
            "
            @change="username_change"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-textbox-password"
            v-model="password"
            :counter="10"
            :rules="passRules"
            label="Password"
            type="password"
            required
            :error="errors.password.error"
            :error-count="
              errors.password.error ? errors.password.messages.length : 1
            "
            :error-messages="
              errors.password.error ? errors.password.messages : []
            "
            @change="password_change"
          ></v-text-field>
          <v-checkbox
            v-model="checkbox"
            label="Remember me"
            required
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <!-- <v-spacer></v-spacer> -->
        <v-btn @click="login" color="primary">Login</v-btn
        ><v-btn @click.stop="reset">Reset</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EventBus from "@/utils/eventBus";
import _ from "lodash";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      checkbox: true,
      valid: false,
      nameRules: [v => !!v || "Name is required"],
      passRules: [v => !!v || "Password is required"],
      info: false,
      error: false,
      errors: {
        username: { error: false, count: 0, messages: [] },
        password: { error: false, count: 0, messages: [] }
      }
    };
  },
  methods: {
    login() {
      // console.log("before", this.valid);
      if (!this.$refs.form.validate()) {
        return;
      }
      let username = this.username;
      let password = this.password;
      this.error = false;
      this.info = false;
      this.loading = true;
      this.$store
        .dispatch("login", { username, password })
        .then(() => this.$router.push("/app"))
        .catch(err => {
          // console.log(err);
          this.loading = false;
          if (err.response) {
            if (_.has(err, "response.data.detail")) {
              this.error = err.response.data.detail;
            } else {
              if (_.has(err, "response.data.username")) {
                this.errors.username.error = true;
                this.errors.username.messages = err.response.data.username;
              }
              if (_.has(err, "response.data.password")) {
                this.errors.password.error = true;
                this.errors.password.messages = err.response.data.password;
              }
            }
          } else {
            // console.log(err);
          }
        });
      // this.$store.dispatch("login_error");
    },
    reset() {
      this.error = false;
      this.errors.username.error = false;
      this.errors.password.error = false;
      this.$refs.form.reset();
      // this.$refs.form.resetValidation();
    },
    // username_change: _.debounce(function() {
    //   this.errors.username.error = false;
    //   console.log("changed");
    // }, 500)
    username_change: function() {
      this.errors.username.error = false;
      // console.log("changed");
    },
    password_change: function() {
      this.errors.password.error = false;
      // console.log("changed");
    }
  },
  mounted() {
    EventBus.$on("USER_LOGIN_ERROR", args => {
      this.loading = false;

      // console.log(args);
      Object.assign(this.errors, args);
    });

    EventBus.$on("SHOW_INFO", info => {
      this.info = info;
    });
  }
};
</script>

<style></style>
