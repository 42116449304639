import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueLetterAvatar from "vue-letter-avatar";
import Axios from "axios";
import VueNativeSock from "vue-native-websocket";
import EventBus from "@/utils/eventBus";

const WS_MESSAGES = process.env.VUE_APP_WS_ENDPOINT;

Vue.use(VueNativeSock, WS_MESSAGES, {
  connectManually: false,
  store: store,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
});

Vue.prototype.$http = Axios;
Vue.prototype.$EventBus = EventBus;

Vue.config.productionTip = false;

Vue.use(VueLetterAvatar);

Vue.filter("prettyBytes", function(num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? "-" : "") + num + " B";
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? "-" : "") + num + " " + unit;
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueLetterAvatar,
  render: h => h(App)
}).$mount("#app");
