<template>
  <div>
    <v-dialog width="660" v-model="dialog" persistent :loading="loading"
      ><template v-slot:activator="{ on }">
        <v-btn color="success" class="ma-2" dark v-on="on"
          ><v-icon left>mdi-folder-account-outline</v-icon> Add Shared
          Drive</v-btn
        >
      </template>
      <v-card>
        <v-card-title>
          Add New Shared Drive
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="form">
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    v-model="account"
                    @change="email_changed"
                    :rules="emailRules"
                    label="Owner *"
                    :items="google_accounts"
                    item-text="email"
                    item-value="id"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Drive Name *"
                    required
                    v-model="drive_name"
                    :error-messages="drive_name_error"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="current_drives"
                    label="Current Drives"
                    readonly
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="adding"
            color="primary"
            @click="add_shared_drive"
            :disabled="!form"
          >
            Add
          </v-btn>
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventBus from "@/utils/eventBus";

export default {
  data() {
    return {
      owner: "",
      adding: false,
      form: false,
      loading: true,
      client_id: "",
      drive_name: "",
      api_key: "",
      redirect_url: `${process.env.VUE_APP_API_BASE}/api/google_auth`,
      token_url: "https://www.googleapis.com/oauth2/v4/token",
      client_id_error: "",
      drive_name_error: "",
      redirect_url_error: "",
      token_url_error: "",
      current_drives: [],
      account_errors: [],
      dialog: false,
      is_private: false,
      account: "",
      proxy_only: false,
      required: value => !!value || "Required.",
      emailRules: [
        v => !!v || "E-mail is required"
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    };
  },
  watch: {},
  computed: {
    shared_drives: function() {
      return this.$store.getters.shared_drives;
    },
    // current_drives: function() {
    //   return (
    //     this.shared_drives
    //       .filter(sd => sd.email == this.owner)
    //       .map(({ drive_name }) => drive_name) || []
    //   );
    // },
    google_accounts: function() {
      return this.$store.getters.google_accounts.filter(
        ac => ac.status == 1 && ac.proxy_only == false
      );
    }
  },

  methods: {
    close() {
      this.dialog = false;
      this.drive_name = "";
      this.account = "";
      this.current_drives = [];
    },
    email_changed(item) {
      // console.log(item);
      if (typeof item != "object" || item == null) {
        return;
      }

      let items = this.shared_drives.filter(sd => sd.owner.email == item.email);
      // this.owner = item.email;
      // items;
      this.current_drives = items.map(a => a.drive_name);
    },
    add_shared_drive() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let drive_info = {
        account_id: this.account.id,
        drive_name: this.drive_name
      };
      // console.log(drive_info);
      this.adding = true;
      this.$store
        .dispatch("add_shared_drive", drive_info)
        .then(() => {
          this.dialog = false;
          this.account = "";
          this.$store.dispatch("fetch_shared_drives");
        })
        .catch(err => {
          if (err.response) {
            // console.log(err.response);
            if (err.response.status >= 500) {
              EventBus.$emit("SNACKBAR", {
                color: "error",
                message: "Server Error"
              });
              //   console.log("snackbar");
            } else if (err.response.data.error) {
              this.api_key_error = err.response.data.error;
            }
          } else if (err.request) {
            err.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            "Error", err.message;
          }
        })
        .finally(() => (this.adding = false));
    }
  }
};
</script>
